import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../../http/http";
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

const Users = () => {
    const [inviteModal, setinviteModal] = useState(false)
    const { register, handleSubmit, reset, watch } = useForm({
        defaultValues: {
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            phone_no: '',
            designation_id: '',
            roles: [],
        }
    });

    const [designationList, setDesignationList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUserDetails, setSelectedUserDetails] = useState(null);




    // Handle row click to show the 'shwdetail' class
    const handleRowClick = (userId) => {
        setSelectedUserId(userId); // Set the selected user ID
        try {
            http(
                {
                    method: "GET",
                    url: `adminusers/${userId}/view`,
                    isSecure: true,
                },
                (res) => {
                    setSelectedUserDetails(res?.data);
                },
            )
        }
        catch (error) {

        }
    };

    // Handle click on icon to remove 'shwdetail' class
    const handleIconClick = () => {
        console.log("click")
        setSelectedUserId(null); // Clear the selected user to remove the 'shwdetail' class
    };
    const AddUsersHandle = async (data) => {
        try {
            http(
                {
                    method: "POST",
                    url: `adminusers/add`,
                    isSecure: true,
                    body: {
                        first_name: data.first_name,
                        last_name: data.last_name,
                        middle_name: data.middle_name,
                        email: data.email,
                        phone_no: data.phone_no,
                        designation_id: parseInt(data.designation_id), // Assuming designation ID is a number
                        roles: data.roles, // This is an array of roles
                    },
                },
                (res) => {
                    console.log("User added successfully", res);
                    setinviteModal(false); // Close the modal after successful submission
                    reset(); // Reset form after submission
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };



    const handleDesignationList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `designation/listing`,
                    isSecure: true,
                },
                (res) => {
                    setDesignationList(res?.data?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };
    useEffect(() => {

        handleDesignationList();
    }, []);

    const handleRoleList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `roles`,
                    isSecure: true,
                },
                (res) => {
                    setRoleList(res?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };
    useEffect(() => {

        handleRoleList();
    }, []);

    const handleUserList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `adminusers`,
                    isSecure: true,
                },
                (res) => {
                    setUserList(res?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };
    useEffect(() => {

        handleUserList();
    }, []);

    const handleStatusChange = async (userId, status) => {
        try {
            // Sending the updated status in the required format
            http(
                {
                    method: 'PUT',
                    url: `adminusers/status/${userId}`, // Dynamic user ID
                    isSecure: true,
                    body: {
                        status: status, // Ensure the status is sent as a string
                    },
                },
                (res) => {
                    console.log('Status updated successfully', res);

                    // Update the selected user status in state to reflect the new status in the UI
                    setSelectedUserDetails((prevDetails) => ({
                        ...prevDetails,
                        status: parseInt(status),
                    }));
                    handleUserList();
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error('Error updating status', error);
        }
    };

    // Function to handle user deletion
    const handleDelete = async (userId) => {
        try {
            http(
                {
                    method: 'DELETE',
                    url: `adminusers/${userId}/delete`, // Append the user ID to the URL
                    isSecure: true,
                },
                (res) => {
                    console.log('User deleted successfully', res);

                    // Optionally, update the local state to remove the user from the UI
                    setUserList((prevList) => prevList.filter((user) => user.id !== userId));

                    // Clear selected user details after deletion
                    setSelectedUserDetails(null);
                    handleUserList();
                    setSelectedUserId(null);
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    return (
        <>
            <div className="userdetailbox">
                <div className={`leftdetbox ${selectedUserId ? 'shwdetail' : ''}`}>
                    <div className="bg-white rounded-4 shadow-main h-800 overflow-y-scroll pb-4">
                        <div className="accord-head d-flex align-items-start justify-content-between px-4 pt-4 pb-0">
                            <div>
                                <h4 className="fs-20 font-weight-bold">Users</h4>
                                <p className="t_blue">Users & Roles</p>
                            </div>
                            <button className="add-new" onClick={() => setinviteModal(true)}>
                                <i className="fa fa-plus me-2"></i>
                                Invite User
                            </button>
                            {/* <button className="add-new-button"></button> */}
                        </div>
                        <div className="search-drop pt-2 px-4 pb-4 d-flex align-items-center justify-content-between flex-wrap gap-3">

                            <form class="searchuser d-flex align-items-center gap-2">
                                <input type="search" class="form-input" name="search" placeholder="Search" />
                                <i className="fa fa-search"></i>
                            </form>

                            <select className="form-input " name="" id="">
                                <option value="">Status</option>
                                <option value="">All</option>
                                <option value="">All</option>
                                <option value="">All</option>
                                <option value="">All</option>
                                <option value="">All</option>
                                <option value="">All</option>
                            </select>
                        </div>
                        <div className="tableuser px-4 pb-0 table-responsive">
                            <table className="w-100">
                                <thead className="pb-2">
                                    <tr>
                                        <th>User</th>
                                        <th>Role</th>
                                        <th>Designation</th>
                                        <th>Email</th>
                                        <th>Phone No.</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userList.map((user) => (
                                        <tr
                                            onClick={() => handleRowClick(user.id)}
                                            className={selectedUserId === user.id ? 'selected-row' : ''}
                                        >
                                            <td>
                                                <img src={require("../../../../assets/images/user-pic.png")} className="me-2" alt="" />
                                                {user?.name}
                                            </td>

                                            <td>{user?.roles?.join(', ')}</td>
                                            <td>{user?.designation_id?.name}</td>
                                            <td>{user?.email}</td>
                                            <td>{user?.phone_no || 'N/A'}</td>
                                            <td>
                                                {user.status === "1" ? (
                                                    <span className="active">Active</span>
                                                ) : (
                                                    <span className="inactive">Inactive</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {selectedUserDetails && (
                    <div className={`rightdetbox ${selectedUserId ? 'shwdetail' : ''}`}>
                        <div className="bg-white shadow---main p-4 rounded-4">
                            <div className="d-flex align-items-center gap-4 justify-content-between">
                                <h4 className="fs-20">User Details</h4>
                                <div className="r_usrhead d-flex alifgn-items-center gap-2">
                                    <div className="editicon" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                                            <g id="write" transform="translate(-7.998 -8)">
                                                <path id="Path_3528" data-name="Path 3528" d="M8.3,26.038,8,28.469a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#202529" />
                                                <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.472,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.859a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#202529" />
                                                <path id="Path_3530" data-name="Path 3530" d="M36.875,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.708A.583.583,0,0,0,36.875,52.583Z" transform="translate(-14.877 -31.168)" fill="#202529" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="deticon"
                                    //  onClick={() => handleDelete(selectedUserDetails.id)} 

                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.598" height="13.998" viewBox="0 0 12.598 13.998">
                                            <path id="Path_3741" data-name="Path 3741" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-399 -1335)" fill="#202529" fill-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <img src={require("../../../../assets/images/circle-xmark.png")} className="object-fit-contain" alt="" onClick={handleIconClick} />
                                </div>
                            </div>
                            <div className="usrhead d-flex align-items-center gap-4 justify-content-between mt-3">
                                <div className="lhead d-flex align-items-center gap-3">
                                    <img src={require("../../../../assets/images/userman.jpg")} className="rounded-4" alt="" />
                                    <div>
                                        <h6 className="fs-18 fw-semibold">{selectedUserDetails.name}</h6>
                                        <span className="d-flex align-items-center gap-2">
                                            <img src={require("../../../../assets/images/email.png")} className="emailicon" alt="" />
                                            <p className="fs-14 text-muted">{selectedUserDetails.email}</p>
                                        </span>
                                    </div>
                                </div>
                                <div className="rhead">
                                    <select
                                        value={selectedUserDetails.status} // Set the current status as the selected option
                                        onChange={(e) =>
                                            handleStatusChange(selectedUserDetails.id, e.target.value) // Capture the selected value and send the API call
                                        }
                                    >
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="row">
                                <div className="col-lg-6 p-0 mb-3">
                                    <p className="fs-14 text-muted">First Name</p>
                                    <h6 className="fs-18 fs-medium">{selectedUserDetails?.first_name}</h6>
                                </div>
                                <div className="col-lg-6 p-0 mb-3">
                                    <p className="fs-14 text-muted">Middle Name</p>
                                    <h6 className="fs-18 fs-medium">{selectedUserDetails?.middle_name}</h6>
                                </div>
                                <div className="col-lg-6 p-0 mb-3">
                                    <p className="fs-14 text-muted">Last Name</p>
                                    <h6 className="fs-18 fs-medium">{selectedUserDetails?.last_name}</h6>
                                </div>
                                <div className="col-lg-6 p-0 mb-3">
                                    <p className="fs-14 text-muted">Designation</p>
                                    <h6 className="fs-18 fs-medium"> {selectedUserDetails.designation_id?.name}</h6>
                                </div>
                                <div className="col-lg-6 p-0 mb-3">
                                    <p className="fs-14 text-muted">Email Address</p>
                                    <h6 className="fs-18 fs-medium">{selectedUserDetails.email}</h6>
                                </div>
                                <div className="col-lg-6 p-0 mb-3">
                                    <p className="fs-14 text-muted">Phone No.</p>
                                    <h6 className="fs-18 fs-medium">{selectedUserDetails.phone_no || 'N/A'}</h6>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <h4 className="fs-semibold fs-20">Assigned Roles</h4>
                            <ul className="mt-3 ps-0 assignrole">
                                <li className="fs-18 fw-medium"><span></span>{selectedUserDetails.roles.join(', ')}</li>

                            </ul>
                            <hr className="my-4" />
                            {/* <button className="btn btn-dark">Generate Password</button> */}
                        </div>
                    </div>

                )}
            </div>


            {/* MODAL START */}
            <Modal
                show={inviteModal}
                onHide={() => setinviteModal(false)}
                centered
                size="md"
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header p-0 justify-content-between align-items-center border-0">
                    <h4 className="fs-20">Invite Modal</h4>
                    <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
                </div>
                <div className="services-form row">
                    <div className="col-lg-12 mt-3 p-0">
                        {/* Form submission handled by React Hook Form */}
                        <form onSubmit={handleSubmit(AddUsersHandle)}>
                            <div className="border-0 justify-content-center p-0">
                                <label htmlFor="first_name">First Name</label>
                                <input
                                    {...register('first_name', {})}
                                    id="first_name"
                                    className="form-input mx-0 mt-1"
                                    type="text"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0">
                                <label htmlFor="middle_name">Middle Name</label>
                                <input
                                    {...register('middle_name')}
                                    id="middle_name"
                                    className="form-input mx-0 mt-1"
                                    type="text"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0">
                                <label htmlFor="last_name">Last Name</label>
                                <input
                                    {...register('last_name', {})}
                                    id="last_name"
                                    className="form-input mx-0 mt-1"
                                    type="text"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label htmlFor="email">Email</label>
                                <input
                                    {...register('email', {})}
                                    id="email"
                                    className="form-input mx-0 mt-1"
                                    type="email"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label htmlFor="phone_no">Phone No.</label>
                                <input
                                    {...register('phone_no', {})}
                                    id="phone_no"
                                    className="form-input mx-0 mt-1"
                                    type="number"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <select
                                    {...register('designation_id', {})}
                                    className="form-input mx-0 mt-1"
                                >
                                    <option value="">Select Designation</option>
                                    {designationList.length > 0 ? (
                                        designationList.map((designation, index) => (
                                            <option key={index} value={designation.id}>
                                                {designation.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>Loading designations...</option>
                                    )}
                                </select>
                            </div>
                            <hr className="my-4 opacity-25" />
                            <div className="roles">
                                <h4 className="fs--18">Set Roles</h4>
                                <p className="fs--14 text-muted">
                                    You can assign multiple roles to a user for flexible permissions and access.
                                </p>
                                <div className="d-flex gap-4 flex-wrap">
                                    {roleList.map((role) => (
                                        <div key={role.id} className="checks">
                                            <div className="input-grp my-2">
                                                <input
                                                    type="checkbox"
                                                    value={role.name}
                                                    {...register('roles')}
                                                />
                                                <label htmlFor={role.name}>{role.name}</label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-5 border-0">
                                <button type="submit" className="btn-blue ms-auto">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
            {/* MODAL END */}

            {/* <Modal
                show={inviteModal}
                onHide={() => setinviteModal(false)}
                centered
                size="md"
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header p-0 justify-content-between align-items-center border-0">
                    <h4 className="fs-20"> Edit Invite</h4>
                    <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
                </div>
                <div className="services-form row">
                    <div className="col-lg-12 mt-3 p-0">
                        <form
                        //  onSubmit={handleSubmit(AddUsersHandle)}

                        >
                            <div className="border-0 justify-content-center p-0">
                                <label htmlFor="first_name">First Name</label>
                                <input

                                    id="first_name"
                                    className="form-input mx-0 mt-1"
                                    type="text"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0">
                                <label htmlFor="middle_name">Middle Name</label>
                                <input

                                    id="middle_name"
                                    className="form-input mx-0 mt-1"
                                    type="text"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0">
                                <label htmlFor="last_name">Last Name</label>
                                <input

                                    id="last_name"
                                    className="form-input mx-0 mt-1"
                                    type="text"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label htmlFor="email">Email</label>
                                <input

                                    id="email"
                                    className="form-input mx-0 mt-1"
                                    type="email"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <label htmlFor="phone_no">Phone No.</label>
                                <input

                                    id="phone_no"
                                    className="form-input mx-0 mt-1"
                                    type="number"
                                    placeholder="Type here..."
                                />
                            </div>
                            <div className="border-0 justify-content-center p-0 mt-3">
                                <select

                                    className="form-input mx-0 mt-1"
                                >
                                    <option value="">Select Designation</option>
                                    {designationList.length > 0 ? (
                                        designationList.map((designation, index) => (
                                            <option key={index} value={designation.id}>
                                                {designation.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>Loading designations...</option>
                                    )}
                                </select>
                            </div>
                            <hr className="my-4 opacity-25" />
                            <div className="roles">
                                <h4 className="fs--18">Set Roles</h4>
                                <p className="fs--14 text-muted">
                                    You can assign multiple roles to a user for flexible permissions and access.
                                </p>
                                <div className="d-flex gap-4 flex-wrap">
                                    {roleList.map((role) => (
                                        <div key={role.id} className="checks">
                                            <div className="input-grp my-2">
                                                <input
                                                    type="checkbox"
                                                    value={role.name}

                                                />
                                                <label htmlFor={role.name}>{role.name}</label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-5 border-0">
                                <button type="submit" className="btn-blue ms-auto">Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal> */}
        </>
    )
}
export default Users;