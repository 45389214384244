import React, { useState, useEffect, useContext } from "react";
import { http } from "../../../http/http";
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import CreatorComponent from "../../../servay/CreatorComponent";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../App";
import { useNavigate, useLocation } from "react-router-dom";
const Add_Custom_Form = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [forms, setForms] = useState([]);
    const [showSurvey, setShowSurvey] = useState(false);
    const [showCreator, setShowCreator] = useState(false);

    useEffect(() => {
        if (location.state?.showCreator) {
            setShowCreator(true);
        }
    }, [location.state]);

    const saveFormToAPI = async (formJson) => {
        const requestData = {
            // tag_id: 1, // Set your tag_id as needed
            title: "form1",
            data: [formJson], // Wrap the formJson in an array
        };

        try {
            // First API call
            const firstApiResponse = await new Promise((resolve, reject) => {
                http(
                    {
                        method: 'POST',
                        url: `form/add`,
                        isSecure: true,
                        body: requestData,
                    },
                    (res) => {
                        if (res) {
                            resolve(res);
                        } else {
                            reject('Error in first API call');
                        }
                    }
                );
            });

            console.log(firstApiResponse, "firstApiResponse");

            // Assuming firstApiResponse has a property named 'formId'
            const formIdFromFirstApiResponse = firstApiResponse.data.id;

            // Add a check to log the formIdFromFirstApiResponse
            console.log(formIdFromFirstApiResponse, "formIdFromFirstApiResponse");

            // Determine the name for the second API call
            const formName = formJson.title ? formJson.title : `Form_${formIdFromFirstApiResponse}`;

            // Add a check to log the formName
            console.log(formName, "formName");


        } catch (error) {
            console.error('Error in API calls:', error);
        }
    };

    const fetchFormsFromAPI = async () => {
        try {
            const response = await new Promise((resolve, reject) => {
                http(
                    {
                        method: 'GET',
                        url: `form/listing`,
                        isSecure: true
                    },
                    (res) => {
                        if (res) {
                            resolve(res?.data?.data || []);
                        } else {
                            reject('Error in fetching forms');
                        }
                    }
                );
            });
            setForms(response);
        } catch (error) {
            console.error('Error fetching forms:', error);
        }
    };

    const handleSaveForm = async (formJson) => {
        await saveFormToAPI(formJson);
        await fetchFormsFromAPI(); // Ensure the forms are fetched after saving
        // navigate('/admin/setting', { state: { selectedSubComponent: 'CustomFormsComponent' } });
        navigate('/admin/setting');
        setShowCreator(false);
        setShowSurvey(false);
    };


    return (
        <div class="right_section font-poppins pr-0">
            <div className="col-md-12 mt-3">
                <div className="bg-white rounded-4 shadow-main overflow-y-scroll p-4">
                    <div className="accord-head d-flex flex-column justify-content-between px-0 pt-0">
                        <span>
                            <h4 className="fs-20 fw-bold">Add Custom Form</h4>
                            <p className='fs-16 t_blue'>Custom Forms</p>
                        </span>
                    </div>
                    <div className="border-top">
                        {showCreator && <CreatorComponent onSave={handleSaveForm} />}
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Add_Custom_Form;