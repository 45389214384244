import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useParams, useNavigate } from "react-router-dom";
import moment from "moment";


const Directors_Details = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const businessId = JSON.parse(localStorage.getItem("BusinessId"));
    const { directorModal, setDirectorModal, fetchDirData } = props;
    const [inputFields, setInputFields] = useState([{ id: 0 }]);
    const [filterSearchData, setFilterSearchData] = useState(null);

    const [searchTerm, setSearchTerm] = useState(''); // For search input value
    const [names, setNames] = useState([]); // For API response data
    const [loading, setLoading] = useState(false); // To handle loading state
    const [error, setError] = useState(null); // To handle errors
    const [showModal, setShowModal] = useState(false); // To control modal visibility
    const [selectedClient, setSelectedClient] = useState(null); // Store the selected client
    const [clientProfile, setClientProfile] = useState(null);
    const [linkDirectorData, setLinkDirectorData] = useState([])
    const [isLinked, setIsLinked] = useState(false);

    // Function to handle search input changes
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        if (event.target.value) {
            handleSinSearch(event.target.value); // Trigger API call on search
        }
    };
    // Function to make API request when user searches
    const handleSinSearch = async (searchQuery) => {
        try {
            setLoading(true); // Set loading to true when API call starts
            http(
                {
                    method: "POST",
                    url: "clients/search/clientlistingbyname",
                    isSecure: true,
                    body: {
                        search: searchQuery, // Sending search query as the body
                    }
                },
                (res) => {
                    setNames(res.data?.data || []); // Assuming the response has a 'data' field with the names list
                    setLoading(false); // Set loading to false after data is fetched
                },
                (err) => {
                    console.error(err);
                    setError("Failed to fetch names"); // Set error message
                    setLoading(false); // Ensure loading is set to false on error
                }
            );
        } catch (err) {
            console.error(err);
            setError("Failed to fetch names"); // Set error message
            setLoading(false); // Ensure loading is set to false on error
        }
    };

    // Filter the names based on the search term, filtering by the `full_name` field
    const filteredNames = names.filter((client) =>
        client.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Function to handle clicking on a name and show the modal
    const handleNameClick = (client) => {
        setSelectedClient(client); // Store the clicked client's information
        handleClientProfile(client.id); // Fetch the client profile using the selected client's ID
        setShowModal(true); // Show the modal
    };


    // Function to close the modal
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedClient(null); // Clear the selected client when modal closes
    };


    const handleAddFields = () => {
        const newInputFields = [...inputFields, { id: inputFields[inputFields.length - 1].id + 1 }];
        setInputFields(newInputFields);
    };

    const handleRemoveFields = (id) => {
        if (id === 0) return; // Prevent deleting the field with ID 0
        const newInputFields = inputFields.filter((field) => field.id !== id);
        setInputFields(newInputFields);
    };
    const addDirector = () => {
        const DirectorData = formValues.directors.map(director => ({
            // Extracting data for each dependent from the formValues
            first_name: director.first_name,
            middle_name: director.middle_name,
            last_name: director.last_name,
            sin: director.sin,
            date_of_birth: director.date_of_birth,
            email: director.email,
            phone_number: director.phone_number,
            address: director.address,
            city: director.city,
            province: director.province,
            postal_code: director.postal_code,
            shareholder_part: director.shareholder_part,

        }));

        // Submit the dependents data to the server
        http(
            {
                method: "POST",
                url: `corporate/director/${id}/add`,
                body: DirectorData,
                isSecure: true,
            },
            (res) => {
                setDirectorModal(false);
                fetchDirData();
                // Update state or perform any necessary actions with the response data
                reset({ directors: [{ first_name: '', middle_name: '', last_name: '', sin: '', date_of_birth: '', email: '', phone_number: '', address: '', city: '', province: '', postal_code: '', shareholder_part: '' }] }); // Reset form with empty fields
                setInputFields([{ id: 0 }]); // Reset input fieldst the form after successful submission
            },
            (err) => {
                console.error("Error Add Dependent:", err);
            }
        );
    };


    // Function to make API request to get client profile
    const handleClientProfile = (clientId) => {
        try {
            setLoading(true); // Set loading to true when API call starts
            http(
                {
                    method: "GET",
                    url: `clients/${clientId}/view`, // API call with selected client's ID
                    isSecure: true
                },
                (res) => {
                    setClientProfile(res.data || []); // Store the client profile data
                    setLoading(false); // Set loading to false after data is fetched
                },
                (err) => {
                    console.error(err);
                    setError("Failed to fetch client profile"); // Set error message
                    setLoading(false); // Ensure loading is set to false on error
                }
            );
        } catch (err) {
            console.error(err);
            setError("Failed to fetch client profile"); // Set error message
            setLoading(false); // Ensure loading is set to false on error
        }
    };



    // useEffect(() => {
    //     // When filterSearchData changes, update the input field values
    //     if (filterSearchData) {
    //         // Loop through each input field and set its value from filterSearchData
    //         inputFields.forEach((inputField, index) => {
    //             setValue(`directors[${index}].first_name`, filterSearchData.first_name || ''); // Set first name value
    //             setValue(`directors[${index}].middle_name`, filterSearchData.middle_name || ''); // Set middle name value
    //             setValue(`directors[${index}].last_name`, filterSearchData.last_name || '');
    //             setValue(`directors[${index}].sin`, filterSearchData.sin || '');
    //             setValue(`directors[${index}].date_of_birth`, filterSearchData.date_of_birth || '');
    //             setValue(`directors[${index}].email`, filterSearchData.email || '');
    //             setValue(`directors[${index}].phone_number`, filterSearchData.phone_number || '');
    //             setValue(`directors[${index}].address`, filterSearchData.address || '');
    //             setValue(`directors[${index}].city`, filterSearchData.city || '');
    //             setValue(`directors[${index}].province`, filterSearchData.province || '');
    //             setValue(`directors[${index}].province`, filterSearchData.postal_code || '');
    //             setValue(`directors[${index}].province`, filterSearchData.shareholder_part || '');
    //             // Set other input field values in a similar manner
    //         });
    //     }
    // }, [filterSearchData, inputFields, setValue]);



    const handleLinkDirector = () => {
        try {
            setLoading(true); // Set loading to true when API call starts
            http(
                {
                    method: "POST",
                    url: `corporate/director/${clientProfile?.id}/${businessId}/addclient`,
                    isSecure: true,

                },
                (res) => {
                    setLinkDirectorData(res || []); // Assuming the response has a 'data' field with the names list
                    setIsLinked(true);
                    setLoading(false); // Set loading to false after data is fetched
                    onHideHandler();
                    setShowModal(false);
                },
                (err) => {
                    console.error(err);

                    setLoading(false); // Ensure loading is set to false on error
                }
            );
        } catch (err) {
            console.error(err);

            setLoading(false); // Ensure loading is set to false on error
        }
    };

    const onHideHandler = () => {
        setDirectorModal(false);
        setInputFields([{ id: 0 }]); // Reset the input fields to the initial state
        reset(); // Reset the form
    };

    return (
        <>
            <Modal
                show={directorModal}
                onHide={onHideHandler}
                centered
                size="xl"
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >

                <div className="modal-header row border-0">
                    <div className="col-md-12 d-flex justify-content-between align-items-center p-0">
                        <h4 className="fs-20">Add Director Details</h4>
                        <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
                    </div>
                </div>

                {/* <div className="row mt-3">
                <div className="col-md-12">
                    <div className="director-form row">
                        <div className="col-lg-6 mt-2 p-0">
                            <div className="search-input position-relative">
                              
                                <form className="searchbar d-flex align-items-center gap-2" onSubmit={(event) => handleSinSearch(event)}>
                                    <input
                                        type="search"
                                        className="form-input bg-none"
                                        name="search"
                                        placeholder="Search"
                                        {...register('search')}
                                    />
                                    <button type="submit" className="add-new">Search</button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                <div className="row">
                    <div className="col-md-6 dctor-search">
                        <div className="position-relative">
                            <input
                                type="text"
                                className="form-input dtr-srch"
                                placeholder="Search for a name"
                                value={searchTerm}
                                onChange={handleSearch} // Handle input changes
                            />
                            <i className="fa fa-search"></i>
                            <i className="fa fa-times" onClick={() => setSearchTerm('')}></i>

                            {/* Only display the list if the searchTerm is not empty */}
                            {searchTerm && (
                                <ul>
                                    {loading ? (
                                        <li>Loading...</li> // Show loading state while fetching data
                                    ) : error ? (
                                        <li>{error}</li> // Show error message if there's an error
                                    ) : filteredNames.length > 0 ? (
                                        filteredNames.map((client) => (
                                            <li key={client.id} onClick={() => handleNameClick(client)}>
                                                {client.full_name} <p>sd</p>
                                            </li>
                                        ))
                                    ) : (
                                        <li>No names found</li> // Show message if no names match
                                    )}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
                <div className="modal-body p-0">
                    <form onSubmit={handleSubmit(addDirector)}>
                        {inputFields.map((inputField, index) => (
                            <div className="row mt-4" key={inputField.id}>
                                <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="fs-18">First Name</label>
                                    <input
                                        type="text"
                                        name={`directors[${index}].first_name`}
                                        id={`first_name${inputField.id}`}
                                        className="form-input mt-2"
                                        // value={filterSearchData && filterSearchData.first_name ? filterSearchData.first_name : ''}
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].first_name`, { required: "Required", })}

                                    />
                                    {errors?.directors?.[index]?.first_name && (
                                        <span className="error">{errors.directors[index].first_name.message}</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="fs-18">Last Name</label>
                                    <input
                                        type="text"
                                        name={`directors[${index}].middle_name`}
                                        id={`middle_name${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].middle_name`, {})}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="fs-18">Last Name</label>
                                    <input
                                        type="text"
                                        name={`directors[${index}].last_name`}
                                        id={`last_name${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].last_name`, { required: "Required", })}
                                    />
                                    {errors?.directors?.[index]?.last_name && (
                                        <span className="error">{errors.directors[index].last_name.message}</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="fs-18">Date of Birth</label>
                                    <input
                                        type="date"
                                        name={`directors[${index}].date_of_birth`}
                                        id={`date_of_birth${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].date_of_birth`, {})}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="fs-18">Email Address</label>
                                    <input
                                        type="email"
                                        name={`directors[${index}].email`}
                                        id={`email${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].email`, { required: "Required", })}
                                    />
                                    {errors?.directors?.[index]?.email && (
                                        <span className="error">{errors.directors[index].email.message}</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="fs-18">Phone No.</label>
                                    <input
                                        type="number"
                                        name={`directors[${index}].phone_number`}
                                        id={`phone_number${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].phone_number`, {})}

                                    />
                                </div>
                                <div className="col-lg-8 col-md-12 mt-4">
                                    <label className="fs-18">Street Address</label>
                                    <input
                                        type="text"
                                        name={`directors[${index}].address`}
                                        id={`address${inputField.id}`}
                                        className="form-input mt-2 google-address"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].address`, {})}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="fs-18">City</label>
                                    <input
                                        type="text"
                                        name={`directors[${index}].city`}
                                        id={`city${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].city`, {})}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="fs-18">Province</label>
                                    <input
                                        type="text"
                                        name={`directors[${index}].province`}
                                        id={`province${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].province`, {})}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="fs-18">Postal Code</label>
                                    <input
                                        type="text"
                                        name={`directors[${index}].postal_code`}
                                        id={`postal_code${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].postal_code`, {})}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 mt-4">
                                    <label className="fs-18">Shareholder(%)</label>
                                    <input
                                        type="text"
                                        name={`directors[${index}].shareholder_part`}
                                        id={`shareholder_part${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].shareholder_part`, {})}

                                    />
                                </div>

                                <div className="col-lg-8 col-sm-12  mt-4  text-end  align-content-center">
                                    {index !== 0 && <button type="button" onClick={() => handleRemoveFields(inputField.id)} className="btn btn-danger mt-4">Remove</button>}
                                </div>
                            </div>
                        ))}
                        <div className="row">
                            <div className="col-md-12">
                                <button type="button" onClick={handleAddFields} className="p-0 add_field_button bg-transparent border-0 d-flex align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.998" viewBox="0 0 18 17.998">
                                        <g id="add" transform="translate(0.002 -0.003)">
                                            <path id="Subtraction_3" data-name="Subtraction 3" d="M9,18a9,9,0,1,1,9-9A9.011,9.011,0,0,1,9,18ZM5.515,8.238a.7.7,0,0,0,0,1.394H8.3V12.42a.7.7,0,1,0,1.394,0V9.632h2.786a.7.7,0,1,0,0-1.394H9.7V5.45a.7.7,0,0,0-1.394,0V8.238Z" transform="translate(-0.002 0.003)" fill="#004fc5" />
                                        </g>
                                    </svg>
                                    <p className="ms-1 text-blue">Add More</p>
                                </button>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-0 pb-2 mt-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <a ><button className="btn-blue">Add</button></a>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </Modal>
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered
                size="md"
                contentClassName="mx-auto p-4 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header p-0 justify-content-between align-items-center border-0">
                    <div className="lhead d-flex align-items-center gap-3">
                        <img src={require("../../../assets/images/userman.jpg")} className="rounded-circle" alt="" />
                        <div>
                            <h6 className="fs-18 fw-semibold">{clientProfile?.first_name} {clientProfile?.last_name}</h6>
                            <span className="d-flex align-items-center gap-2">
                                <img src={require("../../../assets/images/email.png")} className="emailicon" alt="" />
                                <p className="fs-14 text-muted">{clientProfile?.email}</p>
                            </span>
                        </div>
                    </div>
                    <Modal.Header closeButton className="close-icon border-0 p-0"></Modal.Header>
                </div>

                <hr className="my-3" />

                <div className="services-form row">
                    <div className="col-lg-12 p-0">
                        <form >

                            {clientProfile ? (
                                <div className="row">

                                    <div className="col-lg-6 mb-3 p-0">
                                        <p className="fs-14">First Name</p>
                                        <h4 className="fs-18">{clientProfile?.first_name}</h4>
                                    </div>
                                    <div className="col-lg-6 mb-3 p-0">
                                        <p className="fs-14">Last Name</p>
                                        <h4 className="fs-18">{clientProfile?.last_name}</h4>
                                    </div>
                                    <div className="col-lg-6 mb-3 p-0">
                                        <p className="fs-14">SIN</p>
                                        <h4 className="fs-18">{clientProfile?.sin}</h4>
                                    </div>
                                    <div className="col-lg-6 mb-3 p-0">
                                        <p className="fs-14">Date of Birth</p>
                                        <h4 className="fs-18">{moment(clientProfile?.date_of_birth).format('DD MMM, YYYY')}</h4>
                                    </div>
                                    <div className="col-lg-6 mb-3 p-0">
                                        <p className="fs-14">Email</p>
                                        <h4 className="fs-18">{clientProfile?.email}</h4>
                                    </div>
                                    <div className="col-lg-6 mb-3 p-0">
                                        <p className="fs-14">Phone No.</p>
                                        <h4 className="fs-18">{clientProfile?.primary_contact_person}</h4>
                                    </div>
                                    <hr className="mb-3" />

                                    <div className="col-lg-6 mb-3 p-0">
                                        <p className="fs-14">Street Address</p>
                                        <h4 className="fs-18">{clientProfile?.office_location}</h4>
                                    </div>
                                    <div className="col-lg-6 mb-3 p-0">
                                        <p className="fs-14">City</p>
                                        <h4 className="fs-18">{clientProfile?.city ? clientProfile.city : 'N/A'}</h4>
                                    </div>
                                    <div className="col-lg-6 mb-3 p-0">
                                        <p className="fs-14">Province</p>
                                        <h4 className="fs-18">{clientProfile?.province ? clientProfile.province : 'N/A'}</h4>
                                    </div>
                                    <div className="col-lg-6 mb-3 p-0">
                                        <p className="fs-14">Postal Code</p>
                                        <h4 className="fs-18">{clientProfile?.postal_code ? clientProfile.postal_code : 'N/A'}</h4>
                                    </div>
                                    <div className="col-lg-6 mb-3 p-0">
                                        <p className="fs-14">Shareholder (%)</p>
                                        <h4 className="fs-18">{clientProfile?.shareholder ? clientProfile.shareholder : 'N/A'}</h4>
                                    </div>
                                </div>
                            ) : (
                                <p>No client details available</p>
                            )}
                            <div className="d-flex justify-content-between gap-4">
                                <button type="button" className="btn btn-gray text-dark mt-3" onClick={() => navigate(`/admin/client/individual_profile/${clientProfile?.id}`)}>Go to Profile</button>
                                <button
                                    type="button"
                                    className={`btn btn-lightblue mt-3 ${isLinked ? "greenbtn" : ""}`}
                                    onClick={handleLinkDirector}

                                >
                                    {loading ? "Linking..." : isLinked ? "Linked" : "Link to Director"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>



    )
}
export default Directors_Details;