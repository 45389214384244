import React, { useState, useEffect, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { http } from "../../../http/http";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import InputMask from 'react-input-mask';
import CustomField from "../../customField/CustomField";
import Add_Task_Interaction from "./Add_Task_Interaction";
import Add_Task_Priority from "./Add_Task_Priority";
import Add_Task_Assisgn from "./Add_Task_Assisgn";
import Add_Task_Status from "./Add_Task_Status";
import { GlobalContext } from "../../../../App";
import JoditEditor from "jodit-react";

const config = {
    useSearch: false,
    spellcheck: false,
    enter: "P",
    defaultMode: "1",
    toolbarAdaptive: false,
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    minHeight: 200,
    maxHeight: 500,
    minWidth: null,
    buttons:
        "bold,strikethrough,underline,italic,|,font,fontsize,brush,link,|,ul,ol,align,undo,redo",
    placeHolder: "",
    controls: {
        fontsize: {
            list: [
                "8",
                "9",
                "10",
                "11",
                "12",
                "14",
                "16",
                "18",
                "24",
                "30",
                "36",
                "48",
                "60",
                "72",
                "96",
                "100",
            ],
        },
        font: {
            command: "fontname",
            list: {
                "": "Default",
                "'Open Sans',sans-serif": "Open Sans",
                "Helvetica,sans-serif": "Helvetica",
                "Arial,Helvetica,sans-serif": "Arial",
                "Georgia,serif": "Georgia",
                "Impact,Charcoal,sans-serif": "Impact",
                "Tahoma,Geneva,sans-serif": "Tahoma",
                "Times New Roman',Times,serif": "Times New Roman",
                "Verdana,Geneva,sans-serif": "Verdana",
            },
        },
    },
};

const Add_Main_Tasks = (props) => {
    const editor = useRef(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
        getValues,
        setError,
        clearErrors
    } = useForm({
        mode: "all", defaultValues: {
            name: "",
            description: "",
            interaction_type_id: "0",
            priority_id: "0",
            status_task_id: "0",
            staff_id: [],
            start_date: "",
            start_time: "",
            start_time_format: "AM",
            end_date: "",
            end_time: "",
            end_time_format: "AM",
            estimation_time: "",
            time_spent: "",
        }
    });

    const watchedStaffIds = watch("staff_id", []);
    const { setShowLoader } = useContext(GlobalContext);
    const [showInderaction, setShowInderaction] = useState(false);
    const [showPriority, setShowPriority] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [showAssignOpen, setShowAssignOpen] = useState(false);
    const [showAssisgnModal, setShowAssignModal] = useState(false);
    const [images, setImages] = useState([]); // Array to store image files
    const [imagePreviews, setImagePreviews] = useState([]); // Array to store image previews
    const [clientList, setClientList] = useState([]);
    const [copClientList, setCopClientList] = useState([]);
    const [selectedClient, setSelectedClient] = useState(id);
    const [customFields, setCustomFields] = useState([]);
    const [assigned, setAssigned] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState([]);
    const [interactionList, setInteractionList] = useState([]);
    const [priorityList, setPriorityList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [assignData, setAssignData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [startTimeFormat, setStartTimeFormat] = useState('PM');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    const [endTimeFormat, setEndTimeFormat] = useState('PM');
    const [showDropdowns, setShowDropdowns] = useState(false);
    const [customerType, setCustomerType] = useState('');
    const [taskFor, setTaskFor] = useState('');
    const [clientType, setClientType] = useState('');
    const userId = JSON.parse(localStorage.getItem("__assist_erp"));
    const [searchTerm, setSearchTerm] = useState('');
    const today = new Date().toISOString().split('T')[0];
    const [customFormList, setCustomFormList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [taskdescriptionData, setTaskDescriptionData] = useState("");
    const [dropdownState, setDropdownState] = useState({
        isOpen: false,
        search: '',
        selectedOption: null,
        isPriorityOpen: false,
        prioritySearch: '',
        prioritySelectedOption: null,
        isStatusOpen: false,
        statusSearch: '',
        statusSelectedOption: null,
    });
    const watchedStartDate = watch('start_date', startDate);
    const watchedStartTime = watch('start_time', startTime);
    const watchedStartTimeFormat = watch('start_time_format', startTimeFormat);
    const watchedEndDate = watch('end_date', endDate);
    const watchedEndTime = watch('end_time', endTime);
    const watchedEndTimeFormat = watch('end_time_format', endTimeFormat);


    const editorTextArea = (e) => {
        setTaskDescriptionData(e);
    };


    const handleClear = () => {
        setStartDate('');
        setStartTime('');
        setStartTimeFormat('PM');
        setValue('start_date', '');
        setValue('start_time', '');
        setValue('start_time_format', 'PM');
    };
    const handleClearEnd = () => {
        setEndDate('');
        setEndTime('');
        setEndTimeFormat('PM');
        setValue('end_date', '');
        setValue('end_time', '');
        setValue('end_time_format', 'PM');
    };


    // const handleRadioChange = (e) => {
    //     const value = e.target.value;
    //     setTaskFor(value);
    //     if (value === 'self') {
    //         setClientType('3');
    //         setSelectedClient(userId?.user?.id);
    //         setCustomerType('');
    //     } else {
    //         setClientType('');
    //         setSelectedClient('');
    //     }
    // };

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setTaskFor(value);
        clearErrors('taskFor'); // Clear error when a valid option is selected
        if (value === 'self') {
            setClientType('3');
            setSelectedClient(userId?.user?.id);
            setCustomerType('');
            clearErrors('customerType');
            clearErrors('selectedClient');
        } else {
            setClientType('');
            setSelectedClient('');
        }
    };

    const toggleAssignOpen = () => {
        setShowAssignOpen(!showAssignOpen);
    };






    const handleDropdownOpen = (type) => {
        setDropdownState(prevState => ({
            ...prevState,
            isOpen: type === 'interaction' ? !prevState.isOpen : false,
            isPriorityOpen: type === 'priority' ? !prevState.isPriorityOpen : false,
            isStatusOpen: type === 'status' ? !prevState.isStatusOpen : false
        }));
    };



    const handleCheckboxCustomForm = (event) => {
        const value = parseInt(event.target.value, 10); // Parse the value as an integer

        setSelectedOptions((prevSelectedOptions) => {
            if (prevSelectedOptions.includes(value)) {
                return prevSelectedOptions.filter((option) => option !== value);
            } else {
                return [...prevSelectedOptions, value];
            }
        });
    };


    /* Add Assign Staff Function */

    const handleCheckboxChange = () => {
        const values = getValues('staff_id');
        setAssigned(values.length > 0);

        // Get the selected staff members based on their IDs
        const selectedStaffMembers = assignData.filter(staff => values.includes(staff.id.toString()));
        setSelectedStaff(selectedStaffMembers);
    };

    const displayStaffNames = () => {
        if (selectedStaff.length > 0) {
            // Map each selected staff's first character to an uppercase element
            return selectedStaff.map(staff => (
                <span key={staff.id} className="mt-2 bg-blue-light assignee_name" data-bs-toggle="tooltip" data-bs-placement="top" title={staff.name}>
                    {staff.name.charAt(0).toUpperCase()}

                </span>
            ));
        }
        return (
            <span className="select-box" onClick={toggleAssignOpen}>
                Unassigned
            </span>
        );
    };

    /* Add Assign Staff Function End */

    /* Add Image Function amd Delete Image */

    // const handleFileChange = (e) => {
    //     const newFiles = Array.from(e.target.files);
    //     const newFilePreviews = newFiles.map((file) => {
    //         const fileType = file.type;
    //         let preview = '';
    //         if (fileType === 'application/pdf') {
    //             preview = require('../../../../assets/images/pdfimage.png'); // Replace with your PDF image path
    //         } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    //             preview = require('../../../../assets/images/docimage.png'); // Replace with your DOC image path
    //         } else if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    //             preview = require('../../../../assets/images/xlsimage.png'); // Replace with your XLSX image path
    //         } else {
    //             preview = URL.createObjectURL(file);
    //         }
    //         return preview;
    //     });

    //     setImages((prevImages) => [...prevImages, ...newFiles]);
    //     setImagePreviews((prevPreviews) => [...prevPreviews, ...newFilePreviews]);
    // };


    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);
        processFiles(newFiles);
    };

    const processFiles = (newFiles) => {
        const newFilePreviews = newFiles.map((file) => {
            const fileType = file.type;
            let preview = '';

            if (fileType === 'application/pdf') {
                preview = require('../../../../assets/images/pdfimage.png'); // Replace with your PDF image path
            } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                preview = require('../../../../assets/images/docimage.png'); // Replace with your DOC image path
            } else if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                preview = require('../../../../assets/images/xlsimage.png'); // Replace with your XLSX image path
            } else {
                preview = URL.createObjectURL(file);
            }
            return preview;
        });

        setImages((prevFiles) => [...prevFiles, ...newFiles]);
        setImagePreviews((prevPreviews) => [...prevPreviews, ...newFilePreviews]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy'; // Show copy cursor
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Add any additional visual indication for drag enter
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Remove visual indication for drag leave
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const newFiles = Array.from(e.dataTransfer.files);
        processFiles(newFiles);
    };
    const handleDeleteImage = (index) => {
        setImages(images.filter((_, idx) => idx !== index));
        setImagePreviews(imagePreviews.filter((_, idx) => idx !== index));
    };

    /* Add Image Function amd Delete Image End */

    /* Add Custom Filed Function */

    const handleCustomFieldsChange = (fields) => {
        setCustomFields(fields);
    };
    /* Add Custom Filed Function */

    /* Add InderactionType Function */

    const handleInteractionChange = (option) => {
        if (option.id === "add-more") {
            setShowInderaction(true);
            setDropdownState(prevState => ({ ...prevState, selectedOption: null }));
            setValue("interaction_type_id", "0");
        } else {
            setValue("interaction_type_id", option.id);
            clearErrors("interaction_type_id"); // Clear error when a valid option is selected
        }
    }

    const filteredOptions = interactionList.filter(option =>
        option.name.toLowerCase().includes(dropdownState.search.toLowerCase())
    );
    const handleSelect = (option) => {
        setDropdownState(prevState => ({
            ...prevState,
            selectedOption: option,
            isOpen: false,
            search: ''
        }));
        handleInteractionChange(option);
    };


    /* Add InderactionType Function End */

    /* Add PriorityType Function */

    const handleSelectPriority = (option) => {
        setDropdownState(prevState => ({
            ...prevState,
            prioritySelectedOption: option,
            isPriorityOpen: false,
            prioritySearch: ''
        }));
        handlePriorityChange(option);
    };
    const handlePriorityChange = (option) => {
        if (option.id === "add-more") {
            setShowPriority(true);
            // Reset the select to the default value
            setDropdownState(prevState => ({ ...prevState, prioritySelectedOption: null }));
            setValue("priority_id", "0");
        } else {
            setValue("priority_id", option.id);
            clearErrors("priority_id"); // Clear error when a valid option is selected
        }
    };

    const filteredOptionsPriority = priorityList.filter(option =>
        option.name.toLowerCase().includes(dropdownState.prioritySearch.toLowerCase())
    );

    /* Add PriorityType Function End */

    /* Add StatusType Function */

    const handleStatusChange = (option) => {
        if (option.id === "add-more") {
            setShowStatus(true);
            // Reset the select to the default value
            setDropdownState(prevState => ({ ...prevState, statusSelectedOption: null }));
            setValue("status_task_id", "0");
        } else {
            setValue("status_task_id", option.id);
            clearErrors("status_task_id"); // Clear error when a valid option is selected
        }
    };

    const handleSelectStatus = (option) => {
        setDropdownState(prevState => ({
            ...prevState,
            statusSelectedOption: option,
            isStatusOpen: false,
            statusSearch: ''
        }));
        handleStatusChange(option);
    };

    const filteredOptionsStatus = statusList.filter(option =>
        option.name.toLowerCase().includes(dropdownState.statusSearch.toLowerCase())
    );

    /* Add StatusType Function End */


    /* Add ClientType Function */


    const handleCustomerTypeChange = (e) => {
        const value = e.target.value;
        setCustomerType(value);
        setSelectedClient('');
        clearErrors('customerType'); // Clear error when a valid option is selected
        if (value === 'individual') {
            setClientType('1');
        } else if (value === 'business') {
            setClientType('2');
        } else {
            setClientType('');
        }
    };
    const handleSelectChange = (event) => {
        const clientId = event.target.value;
        setSelectedClient(clientId);
        clearErrors('selectedClient'); // Clear error when a valid option is selected
    };

    const handleCopSelectChange = (event) => {
        const clientId = event.target.value;
        setSelectedClient(clientId);
        clearErrors('selectedClient'); // Clear error when a valid option is selected
    };

    /* Add ClientType Function End */

    /* Add GET Method Client List Api Calling Function */

    const getClientList = () => {

        http(
            {
                method: "GET",
                url: "clients",
                isSecure: true,

            },
            (res) => {
                setClientList(res.data?.data);
            },
            (err) => {

            }
        )
    }
    useEffect(() => {
        getClientList();
    }, []);

    /* Add GET Method Client List Api Calling Function End */

    /* Add GET Method Corporate Client List Api Calling Function */

    const getCopClientList = () => {

        http(
            {
                method: "GET",
                url: "corporate/clients",
                isSecure: true,

            },
            (res) => {
                setCopClientList(res.data?.data);
            },
            (err) => {

            }
        )
    }
    useEffect(() => {
        getCopClientList();
    }, []);

    /* Add GET Method Corporate Client List Api Calling Function End */

    /* Add POST Method Submit Add Task Api Calling Function  */

    const AddTaskSubmit = async (data) => {
        setShowLoader(true);
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            if (key !== "staff_id" && key !== "file") {
                formData.append(key, data[key]);
            }
        });
        formData.append("description", taskdescriptionData)

        if (data.staff_id) {
            data.staff_id.forEach(id => {
                formData.append('staff_id[]', id);
            });
        }

        if (images.length > 0) {
            images.forEach((image, index) => {
                formData.append(`file[]`, image, image.name); // The 'images[]' will help PHP-like backends recognize the array
            });
        }

        // Append form IDs from selectedOptions state
        selectedOptions.forEach(id => {
            formData.append('form_id[]', id);
        });


        if (clientType === "1" || clientType === "2") {
            formData.append("client_id", selectedClient);
        } else if (clientType === "3") {
            formData.append("user_id", userId?.user?.id);
        }
        formData.append("task_type", clientType);

        const apiUrl = `client/outertask/add`;

        // const apiUrl = clientType === 'corporate' ? `corporate/task/${selectedClient}/add` : `client/task/${selectedClient}/add`;

        // console.log("Submitting to URL:", apiUrl); // Debugging line to check the URL

        http(
            {
                method: "POST",
                url: apiUrl,
                isSecure: true,
                contentType: "multipart/form-data",
                body: formData
            },
            (res) => {
                toast.success(res.message);
                reset();
                navigate("/admin/task")
                // handleTaskList();
                setImages([]);
                setImagePreviews([]);
                localStorage.setItem('taskClientId', JSON.stringify(res?.data?.client?.id));
                setShowLoader(false);
            },
            (err) => {
                toast.error("Error submitting the task.");
                console.error("Submission error:", err); // Additional debugging for error
                setShowLoader(false);
            }
        );
    }

    const onSubmit = async (data) => {
        const customFields = getValues("custom_field") || {}; // Ensure customFields is an object

        // Check if customFields is an object and has keys
        if (typeof customFields === 'object' && customFields !== null) {
            Object.keys(customFields).forEach(key => {
                data[`custom_field[${key}]`] = customFields[key];
            });
        }
        // Custom validation logic
        let hasErrors = false;
        if (!data.interaction_type_id || data.interaction_type_id === "0") {
            setError('interaction_type_id', { type: 'manual', message: ' required' });
            hasErrors = true;
        }
        if (!data.priority_id || data.priority_id === "0") {
            setError('priority_id', { type: 'manual', message: 'required' });
            hasErrors = true;
        }
        if (!data.status_task_id || data.status_task_id === "0") {
            setError('status_task_id', { type: 'manual', message: 'required' });
            hasErrors = true;
        }
        if (!taskFor) {
            setError('taskFor', { type: 'manual', message: 'required' });
            hasErrors = true;
        }

        if (taskFor === 'customer' && !customerType) {
            setError('customerType', { type: 'manual', message: 'required' });
            hasErrors = true;
        }

        if (taskFor === 'customer' && !selectedClient) {
            setError('selectedClient', { type: 'manual', message: 'required' });
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }


        clearErrors('interaction_type_id');
        clearErrors('priority_id');
        clearErrors('status_task_id');

        await AddTaskSubmit(data);
    };
    /* Add POST Method Submit Add Task Api Calling Function End  */

    /* Add GET Method  Interraction Type Api Calling Function  */

    const handleInteractionData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `interaction_type/listing`,
                    isSecure: true,
                },
                (res) => {
                    setInteractionList(res?.data?.data || []);
                    const defaultInteraction = res?.data?.data.find(item => item.name === "General");
                    if (defaultInteraction) {
                        setValue("interaction_type_id", defaultInteraction.id.toString());
                        setDropdownState(prevState => ({
                            ...prevState,
                            selectedOption: defaultInteraction
                        }));
                    }
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleInteractionData();
    }, []);

    /* Add GET Method  Priority Api Calling Function  */


    const handlePriorityData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `priority/listing`,
                    isSecure: true,
                },
                (res) => {
                    setPriorityList(res?.data?.data || []);
                    const defaultPriority = res?.data?.data.find(item => item.name === "Normal");
                    if (defaultPriority) {
                        setValue("priority_id", defaultPriority.id.toString());
                        setDropdownState(prevState => ({
                            ...prevState,
                            prioritySelectedOption: defaultPriority
                        }));
                    }
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handlePriorityData();
    }, []);

    /* Add GET Method  Priority Api Calling Function End  */

    /* Add GET Method  Status Api Calling Function   */


    const handleStatusData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `status_task/listing`,
                    isSecure: true,
                },
                (res) => {
                    setStatusList(res?.data?.data || []);
                    const defaultValueStatus = res?.data?.data.find(item => item.name === "Open");
                    if (defaultValueStatus) {
                        setValue("status_task_id", defaultValueStatus.id.toString());
                        setDropdownState(prevState => ({
                            ...prevState,
                            statusSelectedOption: defaultValueStatus
                        }));
                    }
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleStatusData();
    }, []);

    /* Add GET Method  Status Api Calling Function End  */

    /* Add GET Method  Staff Api Calling Function   */

    const handleAssisgnData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `staff/listing`,
                    isSecure: true,
                },
                (res) => {
                    setAssignData(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleAssisgnData();
    }, []);

    /* Add GET Method  Staff Api Calling Function End  */



    const handleCustomFormList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `form/listing`,
                    isSecure: true,
                },
                (res) => {
                    setCustomFormList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleCustomFormList();
    }, []);

    return (
        <div class="right_section font-poppins pr--0">
            <div className="add-task pb-3">
                <div className="task-detail-area">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-8 mt-4 ps-0">
                                <div className="task_head"><h4 class="fs-20">Add Task</h4></div>
                                <div className="edit-task-form mt-3">
                                    <div className="create_task">
                                        <h6 className="fs--18 text-grays">Task Create for <span className="text-danger">*</span></h6>
                                        <div className="mt-2 d-flex align-items-center gap-5">
                                            <div className="radiobox d-flex align-items-center gap-2">
                                                <input
                                                    type="radio"
                                                    id="customer"
                                                    name="taskFor"
                                                    className="w-18 h-18"
                                                    value="customer"
                                                    onChange={handleRadioChange}


                                                />
                                                <label htmlFor="customer" className="fs--14 text-secondary">Customer</label>
                                            </div>
                                            <div className="radiobox d-flex align-items-center gap-2">
                                                <input
                                                    type="radio"
                                                    id="self"
                                                    name="taskFor"
                                                    className="w-18 h-18"
                                                    value="self"
                                                    onChange={handleRadioChange}

                                                />
                                                <label htmlFor="self" className="fs--14 text-secondary">Internal</label>
                                            </div>
                                        </div>
                                        {errors.taskFor && (
                                            <div className="error">{errors.taskFor.message}</div>
                                        )}
                                        {taskFor === 'customer' && (
                                            <div className="d-flex gap-3 mt-4 flex-wrap dropdown-section">
                                                <div className="inp-grp mt-2">
                                                    <label htmlFor="" className="fs--18 text-grays fw-regular">
                                                        Select Customer Type
                                                    </label>
                                                    <select className="form-input mt-2" onChange={handleCustomerTypeChange}>
                                                        <option value="">Select</option>
                                                        <option value="individual">Individual</option>
                                                        <option value="business">Business</option>
                                                    </select>
                                                    {errors.customerType && (
                                                        <div className="error">{errors.customerType.message}</div>
                                                    )}
                                                </div>

                                                {customerType === 'individual' && (
                                                    <div className="inp-grp mt-2">
                                                        <label htmlFor="" className="fs--18 text-grays fw-regular">
                                                            Individual Client
                                                        </label>
                                                        <select
                                                            className="form-input border-0 mt-2"
                                                            value={selectedClient}
                                                            onChange={handleSelectChange}
                                                        >
                                                            <option value="" disabled selected>Select Individual Client</option>
                                                            {clientList && clientList.map((data) => (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.first_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.selectedClient && (
                                                            <div className="error">{errors.selectedClient.message}</div>
                                                        )}
                                                    </div>

                                                )}
                                                {customerType === 'business' && (
                                                    <div className="inp-grp mt-2">
                                                        <label htmlFor="" className="fs--18 text-grays fw-regular">
                                                            Corporate Client
                                                        </label>
                                                        <select
                                                            className="form-input border-0 mt-2"
                                                            value={selectedClient}
                                                            onChange={handleCopSelectChange}
                                                        >
                                                            <option value="" disabled selected>Select Corporate Client</option>
                                                            {copClientList && copClientList.map((data) => (
                                                                <option key={data.id} value={data.id}>
                                                                    {data.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {errors.selectedClient && (
                                                            <div className="error">{errors.selectedClient.message}</div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between border-input mt-4 input-trans">
                                        <input
                                            type="text"
                                            class="form-input mt-1 bg-transparent"
                                            name="name"
                                            id="name"
                                            {...register("name", {
                                                required: "Required ",
                                            })}
                                        />
                                        <div class="close-icon border-0 p-0 modal-header">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.999" height="16" viewBox="0 0 15.999 16">
                                                <path id="Union_10" data-name="Union 10" d="M10462.527,9393a1.524,1.524,0,0,1-1.076-2.6l5.393-5.395-5.393-5.4a1.48,1.48,0,0,1-.331-.5,1.524,1.524,0,0,1,1.993-1.992,1.571,1.571,0,0,1,.5.33l5.393,5.4,5.394-5.4a1.522,1.522,0,0,1,2.155,0,1.505,1.505,0,0,1,.333.5,1.523,1.523,0,0,1,0,1.167,1.485,1.485,0,0,1-.333.5l-5.391,5.395,5.393,5.4a1.524,1.524,0,0,1-1.078,2.6,1.48,1.48,0,0,1-.584-.115,1.571,1.571,0,0,1-.5-.33l-5.394-5.4-5.395,5.4a1.525,1.525,0,0,1-.493.33,1.5,1.5,0,0,1-.58.115Z" transform="translate(-10461.002 -9377)" fill="#a6a7a9" />
                                            </svg>
                                            {/* <button type="button" class="btn-close" aria-label="Close"></button> */}
                                        </div>
                                    </div>
                                    {errors?.name && <span className="error">{errors?.name.message}</span>}
                                    {/* <textarea
                                        rows="8"
                                        cols="10"
                                        className="mt-4 bg-white text-black"
                                        placeholder="Description"
                                        name="description"
                                        id="description"
                                        {...register("description", {
                                            required: "Required ",
                                        })}
                                    >
                                    </textarea> */}
                                    <JoditEditor
                                        rows="8"
                                        cols="10"
                                        className="mt-4 bg-white text-black"
                                        ref={editor}
                                        value={
                                            // location?.state?.from?.id
                                            //     ? jobDetail?.specialization?.description
                                            //     : assetsdescriptionData
                                            taskdescriptionData
                                        }
                                        config={config}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={(newContent) => editorTextArea(newContent)}

                                    />
                                    {errors?.description && <span className="error">{errors?.description.message}</span>}

                                    <div class="mt-3">
                                        {/* <div class="main-wrapper">
                                            <div class="upload-main-wrapper d-flex align-items-center justify-content-between w-100 border-1 border-dashed gap-2 bg-white">
                                                <h4 class="fs-14">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="me-2" width="13" height="20" viewBox="0 0 13 20"><path id="XMLID_197_" d="M68.356,0h0a4.663,4.663,0,0,0-3.283,1.331,4.469,4.469,0,0,0-1.36,3.214v8.182a2.786,2.786,0,0,0,5.571,0V8.006a.929.929,0,0,0-1.857,0v4.721a.929.929,0,0,1-1.857,0V4.545a2.681,2.681,0,0,1,.816-1.928,2.8,2.8,0,0,1,1.97-.8,2.76,2.76,0,0,1,2.786,2.727v9.091A4.6,4.6,0,0,1,66.5,18.182a4.663,4.663,0,0,1-3.283-1.331,4.468,4.468,0,0,1-1.36-3.214V4.545a.929.929,0,0,0-1.857,0v9.091a6.256,6.256,0,0,0,1.9,4.5A6.528,6.528,0,0,0,66.5,20,6.44,6.44,0,0,0,73,13.636V4.545A4.6,4.6,0,0,0,68.356,0Z" transform="translate(-59.999)" fill="#202529"></path></svg>Drag &amp; drop here</h4>
                                                <div class="upload-wrapper">
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        name="file"
                                                        onChange={handleFileChange}
                                                        multiple

                                                    />
                                                    <i class="fa fa-upload me-2"></i>
                                                    <span class="file-upload-text">Browse Files</span>
                                                </div>
                                            </div>
                                            <div class="image-preview-area"></div>
                                        </div> */}
                                        <div className="main-wrapper">
                                            <div
                                                className="taskadd upload-main-wrapper d-flex align-items-center justify-content-between w-100 border-1 border-dashed gap-2 bg-white"
                                                onDragOver={handleDragOver}
                                                onDragEnter={handleDragEnter}
                                                onDragLeave={handleDragLeave}
                                                onDrop={handleDrop}
                                            >
                                                <h4 className="fs-14">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="13" height="20" viewBox="0 0 13 20">
                                                        <path id="XMLID_197_" d="M68.356,0h0a4.663,4.663,0,0,0-3.283,1.331,4.469,4.469,0,0,0-1.36,3.214v8.182a2.786,2.786,0,0,0,5.571,0V8.006a.929.929,0,0,0-1.857,0v4.721a.929.929,0,0,1-1.857,0V4.545a2.681,2.681,0,0,1,.816-1.928,2.8,2.8,0,0,1,1.97-.8,2.76,2.76,0,0,1,2.786,2.727v9.091A4.6,4.6,0,0,1,66.5,18.182a4.663,4.663,0,0,1-3.283-1.331,4.468,4.468,0,0,1-1.36-3.214V4.545a.929.929,0,0,0-1.857,0v9.091a6.256,6.256,0,0,0,1.9,4.5A6.528,6.528,0,0,0,66.5,20,6.44,6.44,0,0,0,73,13.636V4.545A4.6,4.6,0,0,0,68.356,0Z" transform="translate(-59.999)" fill="#202529"></path>
                                                    </svg>
                                                    Drag &amp; drop here
                                                </h4>
                                                <div className="upload-wrapper">
                                                    <input
                                                        type="file"
                                                        id="file"
                                                        name="file"
                                                        onChange={handleFileChange}
                                                        multiple
                                                    />
                                                    <i className="fa fa-upload me-2"></i>
                                                    <span className="file-upload-text">Browse Files</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-1">

                                        <div className="edit-pics d-flex align-items-center gap-3 ">
                                            <div className="image-preview-area pic_box position-relative mt-3">
                                                {imagePreviews.map((preview, index) => (
                                                    <div key={index} className="image-preview pic_box position-relative mt-3">
                                                        <img className="me-2" src={preview} alt="user-pic" style={{ width: 130, height: 130 }} />
                                                        <div className="cross-pic position-absolute" onClick={() => handleDeleteImage(index)} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                                                <g id="Group_1589" data-name="Group 1589" transform="translate(-1173 -1120)">
                                                                    <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#ccc" />
                                                                    <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#fff" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>

                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div >
                                        <label htmlFor="Required Forms" className="inv-title text-black mb-2">Required Forms</label>
                                        {customFormList && customFormList.map((option) => (
                                            <div key={option.id} className="d-flex align-items-center gap-2 mt-2">
                                                <input
                                                    type="checkbox"
                                                    value={option.id}
                                                    checked={selectedOptions.includes(option.id)}
                                                    onChange={handleCheckboxCustomForm}
                                                />
                                                <label className="text-black">{option.data[0]?.title}</label>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="edit-btns d-flex align-content-center justify-content-between flex-wrap gap-2 mt-5">
                                        <div className="d-flex align-items-center gap-2">
                                            <input type="checkbox" id="template" className="w-24 h-24" />
                                            <label htmlFor="template" className="fs--18 text-grays">Save as Template</label>
                                        </div>
                                        <div className="d-flex align-content-center gap-2">
                                            <button className="btn-cancel">Cancel</button>
                                            <button className="btn-add" type="submit">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-4 mt-2 overflow-hidden">
                                <div class="right-task-edit overflow-y-scroll">
                                    <div className="row mt-4 px-4">
                                        <label className="fs-18 px-1">Interaction Type</label>
                                        <div className={`select-box t_blue ${dropdownState.isOpen ? 'active' : ''}`} onClick={() => handleDropdownOpen('interaction')}>
                                            {dropdownState.selectedOption ? dropdownState.selectedOption.name : "Select"}
                                        </div>
                                        {dropdownState.isOpen && (
                                            <div className="options-container pb-2">
                                                <div className="srchinput position-relative">
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        value={dropdownState.search}
                                                        onChange={e => setDropdownState(prevState => ({ ...prevState, search: e.target.value }))}
                                                        className="search-field t_blue"
                                                    />
                                                    <i className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0"
                                                        onClick={() => setDropdownState(prevState => ({ ...prevState, isOpen: false }))}
                                                    ></i>
                                                </div>
                                                {filteredOptions.map(option => (
                                                    <div
                                                        key={option.id}
                                                        className="option"
                                                        onClick={() => handleSelect(option)}

                                                    >
                                                        {option.name}
                                                    </div>
                                                ))}
                                                <div
                                                    className="option text-blue text-start d-flex align-items-center gap-1"
                                                    onClick={() => {
                                                        handleDropdownOpen(null);
                                                        setShowInderaction(true);
                                                    }}
                                                >
                                                    <span className="addplus">+</span> Add More
                                                </div>
                                            </div>
                                        )}
                                        {errors.interaction_type_id && (
                                            <div className="error">{errors.interaction_type_id.message}</div>
                                        )}
                                    </div>

                                    <div className="row mt-4 px-4 relative">
                                        <label className="fs-18 px-1">Priority</label>

                                        <div className={`select-box ${dropdownState.isPriorityOpen ? 'active' : ''}`} onClick={() => handleDropdownOpen('priority')}>
                                            {dropdownState.prioritySelectedOption ? dropdownState.prioritySelectedOption.name : "Select"}
                                        </div>
                                        {dropdownState.isPriorityOpen && (
                                            <div className="options-container pb-2">
                                                <div className=" srchinput position-relative">
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        value={dropdownState.prioritySearch}
                                                        onChange={e => setDropdownState(prevState => ({ ...prevState, prioritySearch: e.target.value }))}
                                                        className="search-field t_blue"
                                                    />
                                                    <i className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0"
                                                        onClick={() => setDropdownState(prevState => ({ ...prevState, isPriorityOpen: false }))}
                                                    ></i>
                                                </div>
                                                {filteredOptionsPriority.map(option => (
                                                    <div
                                                        key={option.id}
                                                        className="option d-flex align-items-center justify-content-between gap-2"
                                                        onClick={() => handleSelectPriority(option)}

                                                    >
                                                        {option.name}

                                                        <div
                                                            className="color-circle"
                                                            style={{
                                                                backgroundColor: option.color_code,
                                                                width: '8px',
                                                                height: '8px',
                                                                borderRadius: '100%',
                                                                border: option.color_code ? 'none' : '1px solid #ccc'
                                                            }}
                                                        ></div>
                                                    </div>
                                                ))}
                                                <div
                                                    className="option text-blue text-start d-flex align-items-center gap-1"
                                                    onClick={() => {
                                                        handleDropdownOpen(null);
                                                        setShowPriority(true);
                                                    }}
                                                >
                                                    <span className="addplus">+</span> Add More
                                                </div>

                                            </div>
                                        )}
                                        {errors.priority_id && (
                                            <div className="error">{errors.priority_id.message}</div>
                                        )}
                                    </div>

                                    <div className={`row mt-4 px-4 position-relative`}>
                                        <label className="fs-18 px-1" onClick={toggleAssignOpen}>Assignee</label>
                                        <div className="d-flex align-items-center gap-1 flex-wrap select-box" >
                                            {displayStaffNames()}
                                        </div>
                                        {showAssignOpen && (
                                            <div className="options-container px-3 py-2">
                                                <input
                                                    type="text"
                                                    className="form-control mb-2"
                                                    placeholder="Search staff..."
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                                <ul className="ps-0">
                                                    {assignData && assignData
                                                        .filter((staff) => staff.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                                        .map((staff) => (
                                                            <li key={staff.id} className="d-flex justify-content-between align-items-center my-1">
                                                                <label className="fw-semibold text-black">
                                                                    <img src={require("../../../../assets/images/user-pic.png")} className="me-2" alt="user-pic" />
                                                                    {staff?.name}
                                                                </label>
                                                                <input
                                                                    type="checkbox"
                                                                    name="staff_id"
                                                                    id="staff_id"
                                                                    value={staff.id}
                                                                    {...register("staff_id", { onChange: handleCheckboxChange })}
                                                                />
                                                            </li>
                                                        ))}
                                                    <li className="option text-blue d-flex align-items-center gap-1 text-blue mt-1 text-start px-0" onClick={() => setShowAssignModal(true)}>
                                                        <span className="addplus">+</span> Add More
                                                    </li>
                                                </ul>
                                            </div>
                                        )}

                                    </div>
                                    <div className="row mt-4 px-4 position-relative">
                                        <label className="fs-18 px-1">Status</label>
                                        <div className={`select-box ${dropdownState.isStatusOpen ? 'active' : ''}`} onClick={() => handleDropdownOpen('status')}>
                                            {dropdownState.statusSelectedOption ? dropdownState.statusSelectedOption.name : "Select"}
                                        </div>
                                        {dropdownState.isStatusOpen && (
                                            <div className="options-container">
                                                <div className="srchinput position-relative">
                                                    <input
                                                        type="text"
                                                        placeholder="Search..."
                                                        value={dropdownState.statusSearch}
                                                        onChange={e => setDropdownState(prevState => ({ ...prevState, statusSearch: e.target.value }))}
                                                        className="search-field"
                                                    />
                                                    <i className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0"
                                                        onClick={() => setDropdownState(prevState => ({ ...prevState, isStatusOpen: false }))}
                                                    ></i>
                                                </div>
                                                {filteredOptionsStatus.map(option => (
                                                    <div
                                                        key={option.id}
                                                        className="option"
                                                        onClick={() => handleSelectStatus(option)}
                                                    >
                                                        {option.name}
                                                    </div>
                                                ))}
                                                <div
                                                    className="option text-blue text-start d-flex align-items-center gap-1 py-2  mb-2"
                                                    onClick={() => {
                                                        handleDropdownOpen(null);
                                                        setShowStatus(true);
                                                    }}
                                                >
                                                    <span className="addplus">+</span> Add More
                                                </div>
                                            </div>
                                        )}
                                        {errors.status_task_id && (
                                            <div className="error">{errors.status_task_id.message}</div>
                                        )}
                                    </div>

                                    <div className="row px-4 date mt-4">
                                        <h4 className="fs-18 px-1 fw-bold text-black">Start Date</h4>
                                        <div className="col-md-7 ps-0 pe-2">
                                            <label>Date</label>
                                            <input
                                                type="date"
                                                name="start_date"
                                                className="form-input mt-1"
                                                min={today} // Disable dates before today
                                                value={watchedStartDate}
                                                onChange={(e) => {
                                                    setStartDate(e.target.value);
                                                    setValue('start_date', e.target.value);
                                                }}
                                                {...register("start_date")}
                                            />
                                        </div>
                                        <div className="col-md-3 ps-0 pe-2">
                                            <label>Time</label>
                                            <InputMask
                                                mask="99:99"
                                                maskChar={null}
                                                name="start_time"
                                                className={`form-input mt-1 ${errors.start_time ? 'is-invalid' : ''}`}
                                                placeholder="HH:MM"
                                                value={watchedStartTime}
                                                onChange={(e) => {
                                                    setStartTime(e.target.value);
                                                    setValue('start_time', e.target.value);
                                                }}
                                                {...register('start_time')}
                                            />
                                        </div>
                                        <div className="col-md-2 ps-0 pe-1">
                                            <label>Format</label>
                                            <select
                                                className="form-input mt-1 px-3 bg-white"
                                                name="start_time_format"
                                                value={watchedStartTimeFormat}
                                                onChange={(e) => {
                                                    setStartTimeFormat(e.target.value);
                                                    setValue('start_time_format', e.target.value);
                                                }}
                                                {...register("start_time_format")}
                                            >
                                                <option value="PM">PM</option>
                                                <option value="AM">AM</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12 ps-0 pe-2">
                                            <p className="text-danger text-end fs-14 mt-2" onClick={handleClear} style={{ cursor: 'pointer' }}>Clear</p>
                                        </div>

                                    </div>

                                    <div class="row px-4 date mt-4">
                                        <h4 class="fs-16 px-1 fw-bold text-black">End Date</h4>
                                        <div class="col-md-7 ps-0 pe-2">
                                            <label>Date</label>
                                            <input
                                                type="date"
                                                name="end_date"
                                                class="form-input mt-1"
                                                value={watchedEndDate}
                                                min={today} // Disable dates before toda
                                                {...register("end_date", {

                                                })}

                                            />
                                        </div>
                                        <div class="col-md-3 ps-0 pe-2">
                                            <label>Time</label>
                                            <InputMask
                                                mask="99:99"
                                                maskChar={null}
                                                name="end_time"
                                                value={watchedEndTime}
                                                className={`form-input mt-1 ${errors.end_time ? 'is-invalid' : ''}`}
                                                placeholder="HH:MM"
                                                {...register("end_time", {

                                                })}

                                            />
                                        </div>
                                        <div class="col-md-2 ps-0 pe-1">
                                            <label>Format</label>
                                            <select
                                                class="form-input mt-1 px-3 bg-white"
                                                name="end_time_format"
                                                value={watchedEndTimeFormat}
                                                {...register("end_time_format", {

                                                })}
                                            >
                                                <option value="PM">PM</option>
                                                <option value="AM">AM</option>
                                            </select>
                                        </div>
                                        <div class="col-md-12 ps-0 pe-2">
                                            <p className="text-danger text-end fs-14 mt-2" onClick={handleClearEnd}>Clear</p>
                                        </div>
                                    </div>
                                    <div class="row px-4 date mt-4">
                                        <h4 class="fs-16 px-1 fw-bold text-black">Estimation Time</h4>
                                        <div class="row py-2 px-0">
                                            <div class="col-md-12 ps-0 pe-2">
                                                <input
                                                    type="text"
                                                    name="estimation_time"
                                                    class="form-input mt-1 bg-white"
                                                    placeholder="1w 1d 1h 1m"

                                                    {...register("estimation_time", {

                                                    })}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row px-4 date mt-4">
                                        <h4 class="fs-16 px-1 fw-bold text-black">Time Spent</h4>
                                        <div class="row py-2 px-0">
                                            <div class="col-md-12 ps-0 pe-2">
                                                <input
                                                    type="text"
                                                    name="time_spent"
                                                    class="form-input mt-1 bg-white"
                                                    placeholder="1w 1d 1h 1m"
                                                    {...register("time_spent", {

                                                    })}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 px-4 mt-3 mb-5">
                                        <CustomField url="Tasks" register={register} onCustomFieldsChange={handleCustomFieldsChange} />
                                    </div>

                                </div>

                            </div>
                        </div>
                    </form>

                </div>
                <Add_Task_Interaction
                    showInderaction={showInderaction}
                    setShowInderaction={setShowInderaction}
                    handleInteractionData={handleInteractionData}
                />
                <Add_Task_Priority
                    showPriority={showPriority}
                    setShowPriority={setShowPriority}
                    handlePriorityData={handlePriorityData}
                />
                <Add_Task_Assisgn
                    showAssisgnModal={showAssisgnModal}
                    setShowAssignModal={setShowAssignModal}
                    handleAssisgnData={handleAssisgnData}
                />
                <Add_Task_Status
                    showStatus={showStatus}
                    setShowStatus={setShowStatus}
                    handleStatusData={handleStatusData}
                />
            </div>
        </div>
    )
}
export default Add_Main_Tasks;