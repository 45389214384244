import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { http } from "../../http/http";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';



const Add_Reccuring_Invoice = () => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const navigate = useNavigate();
    const [clientType, setClientType] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showCopModal, setShowCopModal] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [invoiceTitle, setInvoiceTitle] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [clientList, setClientList] = useState();
    const [copClientList, setCopClientList] = useState([]);
    const [totalGst, setTotalGst] = useState([]);
    const [amountArray, setAmountArray] = useState([{ 'quantity': 0, 'price': 0, 'type': "1", 'discount': 0, 'rate': 0, 'name': '0', "tax_id": "", "item": "", "descriptions": "", "amount": '' }]);
    const [finalAmountArray, setFinalAmountArray] = useState([]);
    const [finalAmountSubTotal, setFinalAmountSubTotal] = useState(0);
    const [discountItem, setDiscountItem] = useState(0);
    const [gstItemValue, setGstItemValue] = useState(0);
    const [finalGstAmountSubTotal, setFinalGstAmountSubTotal] = useState(0);
    const [selectedClientInfo, setSelectedClientInfo] = useState(null);
    const [selectedCopClientInfo, setSelectedCopClientInfo] = useState(null);
    const [subtotal, setSubtotal] = useState(0.00);
    const [selectedDiscountType, setSelectedDiscountType] = useState("1");
    const [getTaxList, setGetTaxList] = useState([]);
    const [recordCount, setRecordCount] = useState(0);
    const [invoiceNo, setInvoiceNo] = useState('');
    const [deleteInvoice, setDeleteInvoice] = useState(false);
    const [updateInvoiceItem, setUpdateInvoiceItem] = useState(false);
    const [error, setErrors] = useState({});
    const [totalDiscountPrice, setTotalDiscountprise] = useState(0);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [endRecurringDate, setEndRecurringDate] = useState('');
    const today = new Date().toISOString().split('T')[0];
    const [inputFields, setInputFields] = useState([
        { id: 0, item: '', description: '', quantity: '', price: '', item_discount: '', amount: '', item_discount_price_type: '1', tax_id: '' }
    ]);
    const [formData, setFormData] = useState({
        invoice_date: '',
        due_date: '',
        currency: '',
        notes: '',
        client_id: '',
        recurring_start_date: '',
        recurring_end_date: '',
        recurring_expire: '',
        recuring_email_automatic: '',
        recurring_repeat: ''

    });
    const amountArrayConvertion = (amountArray) => {
        const finalAmounts = amountArray.map(item => {
            const amount = item.type === 1
                ? (item.price * item.quantity) - item.discount
                : (item.price * item.quantity) - ((item.price * item.quantity) * item.discount / 100);

            const gstAmount = (amount * item.rate / 100);
            const itemGstAmount = amount + gstAmount;

            return {
                ...item,
                discount_amount: item.type === 1 ? parseFloat(item.discount) || 0 : ((item.price * item.quantity) * item.discount / 100) || 0,
                itemAmount: amount,
                gstAmount: gstAmount,
                itemGstAmount: itemGstAmount
            };
        });

        let totalgst = {};
        let totalDiscount = 0;

        finalAmounts.forEach(item => {
            if (item.tax_id) {
                const key = `${item.name} ${item.rate}%`;
                totalgst[key] = (totalgst[key] || 0) + item.gstAmount;
            }
            totalDiscount += parseFloat(item.discount_amount) || 0;
        });

        const gstTotalNote = Object.keys(totalgst).map(key => ({ type: key, value: totalgst[key] }));

        setTotalGst(gstTotalNote);
        setFinalAmountArray(finalAmounts);

        const totalAmount = finalAmounts.reduce((total, item) => total + item.itemAmount, 0);
        setFinalGstAmountSubTotal(totalAmount);

        const totalFinalAmount = finalAmounts.reduce((total, item) => total + item.itemGstAmount, 0);
        setFinalAmountSubTotal(totalFinalAmount);
        setTotalDiscountprise(totalDiscount.toFixed(2));
    };
    useEffect(() => {
        amountArrayConvertion(amountArray);
    }, [updateInvoiceItem]);

    //discountItem, gstItemValue, selectedDiscountType, deleteInvoice


    const handleDateChange = (event) => {
        setEndRecurringDate(event.target.value);
    };

    const handleRecurringCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
        if (!isCheckboxChecked) {
            setEndRecurringDate(''); // Clear the date when checkbox is checked
        }
    };

    const handleClientTypeChange = (type) => {
        setClientType(type);
        setSelectedClient('');
        setSelectedCopClientInfo(null);
        setSelectedClientInfo(null);
        setErrors((prevErrors) => ({ ...prevErrors, clientType: '', selectedClient: '' }));
    };

    const handleSelectChange = (event) => {
        const clientId = event.target.value;
        setSelectedClient(clientId);

        if (clientId === '+ Add New') {
            setShowModal(true);
        } else {
            const clientInfo = clientList.find(client => client.id == clientId);
            setSelectedClientInfo(clientInfo);
        }
    };

    const handleCopSelectChange = (event) => {
        const clientId = event.target.value;
        setSelectedClient(clientId);

        if (clientId === '+ Add New') {
            setShowCopModal(true);
        } else {
            const clientInfo = copClientList.find(client => client.id == clientId);
            setSelectedCopClientInfo(clientInfo);
        }
    };

    const handleAddFields = () => {
        var arr = amountArray;
        arr.push({ 'quantity': 0, 'price': 0, 'type': 0, 'discount': 0, 'rate': 0, 'name': '0', "tax_id": '', "item": "", "descriptions": "", "amount": '' });
        setAmountArray(arr)
        const newInputFields = [...inputFields, { id: inputFields[inputFields.length - 1].id + 1, item: '', description: '', quantity: '', price: '', item_discount: '', amount: '', item_discount_price_type: '1', tax_id: '' },];
        setInputFields(newInputFields);

    };

    const handleRemoveFields = (id, index) => {
        var arr = amountArray;
        arr.splice(index, 1)
        setAmountArray(arr)
        // setDeleteInvoice(!deleteInvoice)
        setUpdateInvoiceItem(!updateInvoiceItem);
        if (inputFields.length === 1) return;
        const newInputFields = inputFields.filter((field) => field.id !== id);
        setInputFields(newInputFields);
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newAmountArray = [...amountArray];
        newAmountArray[index][name] = value;
        setAmountArray(newAmountArray);
        if (value.trim() !== '') {
            setErrors((prev) => {
                const newErrors = { ...prev };
                if (name === 'item') {
                    delete newErrors[`item${index}`];
                } else if (name === 'descriptions') {
                    delete newErrors[`descriptions${index}`];
                }
                return newErrors;
            });
        }
    };


    const handleTitleChange = (e) => {
        const { value, name } = e.target;
        setInvoiceTitle(value);
        if (value.trim() !== '') {
            setErrors((prev) => ({ ...prev, title: '' })); // Clear error when the user starts typing
        }
    };

    const handleInputDateChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        if (value.trim() !== '') {
            setErrors((prev) => ({ ...prev, [name]: '' })); // Clear error when the user selects a date
        }
    };

    const handleQuantityChange = (id, value, index_val) => {
        setUpdateInvoiceItem(!updateInvoiceItem);
        setFinalGstAmountSubTotal(value);

        // Update the amountArray state with the new quantity
        const updatedAmountArray = amountArray.map((element, index) => {
            if (index === index_val) {
                return { ...element, quantity: value };
            }
            return element;
        });
        setAmountArray(updatedAmountArray);

        // Update the inputFields state with the new quantity and amount
        const newInputFields = inputFields.map(field => {
            if (field.id === id) {
                const discountValue = parseFloat(field.item_discount) || 0; // Default to 0 if discount is empty
                const amount = calculateAmount(parseFloat(value), parseFloat(field.price), discountValue, parseInt(field.item_discount_price_type));
                return { ...field, quantity: value, amount };
            }
            return field;
        });
        setInputFields(newInputFields);

        // Clear error when valid quantity is entered
        if (value.trim() !== '' && !isNaN(value) && parseInt(value) > 0) {
            setErrors((prev) => ({ ...prev, [`quantity${index_val}`]: '' }));
        }
    };

    const handleItemDiscountTypeChange = (id, type, index_val, value) => {
        setSelectedDiscountType(type);
        setUpdateInvoiceItem(!updateInvoiceItem);
        setFinalGstAmountSubTotal(value);

        const discountType = type === '1' ? 1 : 0;

        const newAmountArray = amountArray.map((element, index) => {
            if (index === index_val) {
                return { ...element, type: discountType };
            }
            return element;
        });

        setAmountArray(newAmountArray);

        const newInputFields = inputFields.map(field => {
            if (field.id === id) {
                const discountValue = parseFloat(field.item_discount) || 0; // Default to 0 if discount is empty
                const newAmount = calculateAmount(parseFloat(field.quantity), parseFloat(field.price), discountValue, discountType);
                return {
                    ...field,
                    item_discount_price_type: discountType,
                    amount: newAmount
                };
            }
            return field;
        });

        setInputFields(newInputFields);
    };

    const handleDiscountChange = (id, value, index_val) => {
        setUpdateInvoiceItem(!updateInvoiceItem);

        const newAmountArray = amountArray.map((element, index) => {
            if (index === index_val) {
                return { ...element, discount: value };
            }
            return element;
        });

        setAmountArray(newAmountArray);

        const newInputFields = inputFields.map(field => {
            if (field.id === id) {
                const discountValue = parseFloat(value) || 0; // Default to 0 if discount is empty
                const amount = calculateAmount(parseFloat(field.quantity), parseFloat(field.price), discountValue, parseInt(field.item_discount_price_type));
                return { ...field, item_discount: value, amount };
            }
            return field;
        });

        setInputFields(newInputFields);
    };

    const handlePriceChange = (id, value, index_val) => {
        setUpdateInvoiceItem(!updateInvoiceItem);

        const newAmountArray = amountArray.map((element, index) => {
            if (index === index_val) {
                return { ...element, price: value };
            }
            return element;
        });

        setAmountArray(newAmountArray);

        const newInputFields = inputFields.map(field => {
            if (field.id === id) {
                const discountValue = parseFloat(field.item_discount) || 0; // Default to 0 if discount is empty
                const amount = calculateAmount(parseFloat(field.quantity), parseFloat(value), discountValue, parseInt(field.item_discount_price_type));
                return { ...field, price: value, amount };
            }
            return field;
        });

        setInputFields(newInputFields);

        if (value.trim() !== '' && !isNaN(value) && parseFloat(value) > 0) {
            setErrors(prev => ({ ...prev, [`price${index_val}`]: '' })); // Clear error when valid price is entered
        }
    };
    const handleTaxChange = (event, index_val) => {


        const { value } = event.target;
        // setGstItemValue(value)
        setUpdateInvoiceItem(!updateInvoiceItem);
        const str = "id_34_45_56";
        const splitArray = value.split("_");
        var setArrayVal = amountArray;
        setArrayVal.map((element, index) => {
            if (index == index_val) {
                setArrayVal[index].name = splitArray[1];
                setArrayVal[index].rate = splitArray[2];
                setArrayVal[index].tax_id = splitArray[0];

            }
        });
        setAmountArray(setArrayVal);
        // setDeleteInvoice(!deleteInvoice)
        setUpdateInvoiceItem(!updateInvoiceItem);

    };

    const calculateAmount = (quantity, price, discount, discountType) => {
        let amount = quantity * price;
        if (discountType === 1) { // $ discount
            amount -= discount;
        } else if (discountType === 0) { // % discount
            amount -= (amount * discount / 100);
        }
        return isNaN(amount) ? "0.00" : amount.toFixed(2); // Returns a string representing the amount rounded to 2 decimal places
    };
    useEffect(() => {
        const newSubtotal = inputFields.reduce((acc, field) => acc + parseFloat(field.amount || 0), 0);
        setSubtotal(newSubtotal.toFixed(2));
    }, [inputFields]);


    useEffect(() => {
        if (invoiceTitle.trim() !== '') {
            // getInvoiceNumber();
            // getOrderNumber();
            getCounterRecord();
        } else {
            // setInvoiceNumber('');
            // setOrderNumber(' ');
            getCounterRecord();
        }
    }, [invoiceTitle]);


    // const addClient = async () => {

    //     const formData = new FormData();
    //     Object.entries(formValues).forEach(([key, value]) => {
    //         formData.append(key, value);
    //     });

    //     http(
    //         {
    //             method: "POST",
    //             url: "add-individual",
    //             isSecure: true,
    //             contentType: "multipart/form-data",
    //             body: formData
    //         },
    //         (res) => {
    //             getClientList();
    //         },
    //         (err) => {

    //         }
    //     );
    // }




    const getInvoiceNumber = () => {
        http(
            {
                method: "GET",
                url: "invoice/generateInvoiceNumber",
                isSecure: true,

            },
            (res) => {
                setInvoiceNumber(res?.invoice_number)
            },
            (err) => {

            }
        );
    }
    useEffect(() => {
        getInvoiceNumber();
    }, []);





    const getOrderNumber = () => {
        http(
            {
                method: "GET",
                url: "invoice/generateOrderNumber",
                isSecure: true,

            },
            (res) => {
                setOrderNumber(res.invoice_number)
            },
            (err) => {

            }
        )

    }
    useEffect(() => {
        getOrderNumber();
    }, []);
    const getClientList = () => {

        http(
            {
                method: "GET",
                url: "clients",
                isSecure: true,

            },
            (res) => {
                setClientList(res.data?.data);
            },
            (err) => {

            }
        )
    }
    useEffect(() => {
        getClientList();
    }, []);

    const getCopClientList = () => {

        http(
            {
                method: "GET",
                url: "corporate/clients",
                isSecure: true,

            },
            (res) => {
                setCopClientList(res.data?.data);
            },
            (err) => {

            }
        )
    }
    useEffect(() => {
        getCopClientList();
    }, []);

    const getTaxRate = () => {
        http(
            {
                method: "GET",
                url: "invoice/listingtax",
                isSecure: true,

            },
            (res) => {
                setGetTaxList(res?.data?.data)
            },
            (err) => {

            }
        )

    }
    useEffect(() => {
        getTaxRate();
    }, []);

    const getCounterRecord = () => {
        http(
            {
                method: "GET",
                url: "invoice/reccuring/count",
                isSecure: true,
            },
            (res) => {
                const totalInvoices = res?.data?.total_invoices || 0;
                const newInvoiceNo = `RINV-${String(totalInvoices + 1).padStart(5, '0')}`;
                setRecordCount(totalInvoices);
                setInvoiceNo(newInvoiceNo);
            },
            (err) => {
                console.error("Error fetching record count:", err);
            }
        );
    };

    useEffect(() => {
        getCounterRecord();
    }, []);

    const handleKeyPress = () => {
        setInvoiceNo(`RINV-${String(recordCount + 1).padStart(5, '0')}`);
    };

    const AddInvoice = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const items = amountArray.map(inputField => ({
            name: inputField.item,
            description: inputField.descriptions,
            quantity: parseInt(inputField.quantity),
            price: parseFloat(inputField.price),
            item_discount: parseFloat(inputField.discount),
            item_discount_price_type: inputField.type.toString(),
            tax_id: inputField.tax_id,
            tax_price: inputField.rate
        }));
        const requestBody = {
            invoice_title: invoiceTitle,
            invoice_date: formData.invoice_date,
            due_date: formData.due_date,
            notes: formData.notes,
            currency: formData.currency,
            payment_term: formData.payment_term,
            order_number: formData.order_number,
            client_id: selectedClient,
            company_profile_id: 1,
            recurring_start_date: formData.recurring_start_date,
            recurring_end_date: endRecurringDate, // Update this line
            recurring_expire: isCheckboxChecked ? "1" : "0", // Update this line
            recurring_email_automatic: formData.recurring_email_automatic,
            recurring_repeat: formData.recurring_repeat,
            client_type: clientType === "individual" ? "1" : "2",
            items: items,
            recurring_status: formData.recurring_status,
        };
        http(
            {
                method: "POST",
                url: "invoice/reccuring/add",
                isSecure: true,
                body: requestBody
            },
            (res) => {
                toast.success(res?.message);
                navigate("/admin/invoice/invoice_detail");
            },
            (err) => {
            }
        )
    }

    const validateForm = () => {
        let formIsValid = true;
        let newErrors = {};

        if (invoiceTitle.trim() === '') {
            newErrors.title = 'Title field is required';
            formIsValid = false;
        }

        if (formData.invoice_date.trim() === '') {
            newErrors.invoice_date = 'Invoice Date field is required';
            formIsValid = false;
        }
        if (formData.currency.trim() === '') {
            newErrors.currency = 'Currency field is required';
            formIsValid = false;
        }
        if (clientType === '') {
            newErrors.clientType = 'Client Type is required';
            formIsValid = false;
        } else if (selectedClient === '') {
            // Check if client is selected only when clientType is not empty
            newErrors.selectedClient = 'Client is required';
            formIsValid = false;
        }
        amountArray.forEach((field, index) => {
            if (!field.item || field.item.trim() === '') {
                newErrors[`item${index}`] = 'required';
                formIsValid = false;
            }
            if (!field.descriptions || field.descriptions.trim() === '') {
                newErrors[`descriptions${index}`] = 'required';
                formIsValid = false;
            }
            if (!field.quantity || isNaN(field.quantity) || parseInt(field.quantity) <= 0) {
                newErrors[`quantity${index}`] = 'required';
                formIsValid = false;
            }
            if (!field.price || isNaN(field.price) || parseFloat(field.price) <= 0) {
                newErrors[`price${index}`] = 'required ';
                formIsValid = false;
            }
        });
        setErrors(newErrors);
        return formIsValid;
    };

    return (
        <div className="right_section font-poppins invoicing">
            <div className="row">
                <div className="col-xl-8 offset-md-2 add-invoice-area mt-4 bg-white rounded-4 shadow-main px-3 py-4">
                    <form onSubmit={AddInvoice}>
                        <div className="row p-0">
                            <div className="col-md-12">
                                <h2 className="inv-title">Add New Recurring Invoice</h2>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-12 mt-4 p-0">
                                        <label className="w-100">Title <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            name="invoice_title"
                                            className="form-input mt-3"
                                            placeholder="Invoice"
                                            onChange={handleTitleChange}
                                        />
                                        {error.title && <div className="error">{error.title}</div>}
                                    </div>
                                    <div className="col-md-12 mt-4 p-0">
                                        <label className="w-100">Invoice No.</label>
                                        <input
                                            type="text"
                                            name=""
                                            className="form-input mt-3"
                                            placeholder="RINV-00-02152"
                                            value={invoiceNo}
                                            readOnly
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <label className="w-100">Billing To <span className="text-danger">*</span></label>
                                        <div className="d-flex align-items-center gap-3">
                                            <div className="mt-3 radio-inv d-flex align-items-center gap-1">
                                                <input
                                                    id="indi"
                                                    type="radio"
                                                    name="clientType"
                                                    checked={clientType === 'individual'}
                                                    onChange={() => handleClientTypeChange('individual')}
                                                />
                                                <label htmlFor="indi">Individual Client</label>
                                            </div>
                                            <div className="mt-3 radio-inv d-flex align-items-center gap-1">
                                                <input
                                                    id="corp"
                                                    type="radio"
                                                    name="clientType"
                                                    checked={clientType === 'corporate'}
                                                    onChange={() => handleClientTypeChange('corporate')}
                                                />
                                                <label htmlFor="corp">Corporate Client</label>
                                            </div>
                                        </div>
                                        {error.clientType && <div className="error">{error.clientType}</div>}
                                    </div>
                                    {clientType === 'individual' && (
                                        <div className="col-md-8 mt-4 dropdown-custom">
                                            <div className="drop-new position-relative">
                                                <select
                                                    className="form-input border-0"
                                                    value={selectedClient}
                                                    onChange={handleSelectChange}

                                                >
                                                    <option value="" disabled="disabled" selected>Select Individual Client</option>
                                                    {clientList && clientList.map((data) => (
                                                        <option key={data.id} value={data.id}>
                                                            {data.first_name}
                                                        </option>
                                                    ))}
                                                    {/* <option className="text-blue" style={{ backgroundColor: 'lightblue' }} value="+ Add New">+ Add New</option> */}

                                                </select>
                                                {error.selectedClient && <div className="error">{error.selectedClient}</div>}
                                            </div>
                                        </div>
                                    )}
                                    {clientType === 'corporate' && (
                                        <>
                                            <div className="col-md-8 mt-4 dropdown-custom">
                                                <div className="drop-new position-relative">
                                                    <select
                                                        className="form-input border-0"
                                                        value={selectedClient}
                                                        onChange={handleCopSelectChange}

                                                    >
                                                        <option value="" disabled="disabled" selected>Select Corporate Client</option>
                                                        {copClientList && copClientList.map((data) => (
                                                            <option key={data.id} value={data.id}>
                                                                {data.name}
                                                            </option>
                                                        ))}
                                                        {/* <option className="text-blue" style={{ backgroundColor: 'lightblue' }} value="+ Add New">+ Add New</option> */}
                                                    </select>
                                                    {error.selectedClient && <div className="error">{error.selectedClient}</div>}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {clientType === 'individual' && selectedClientInfo && (
                                        <div className="col-md-12 mt-4 client-info p-0 ms-2">
                                            <div className="info-head d-flex align-items-center justify-content-between">
                                                <h3 className="fs--18">{selectedClientInfo.first_name}</h3>
                                                {/* <a >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                        <g id="Group_856" data-name="Group 856" transform="translate(-971 -344)">
                                                            <rect id="Rectangle_276" data-name="Rectangle 276" width="30" height="30" rx="8" transform="translate(971 344)" fill="#ededee" />
                                                            <g id="write" transform="translate(971.004 344)">
                                                                <path id="Path_3528" data-name="Path 3528" d="M8.3,26.037,8,28.468a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#202529" />
                                                                <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.471,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.858a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#202529" />
                                                                <path id="Path_3530" data-name="Path 3530" d="M36.874,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.707A.583.583,0,0,0,36.874,52.583Z" transform="translate(-14.877 -31.168)" fill="#202529" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </a> */}
                                            </div>
                                            {/* <div className="info-content mt-2">
                                                <p className="fs--14 text--muted">{selectedClientInfo.email}</p>
                                            </div> */}
                                            <div className="info-contact text-end mt-2">
                                                <span className="d-flex align-items-center flex-wrap justify-content-end">
                                                    {/* <p className="fs--14 text--muted">{selectedClientInfo.phone} |</p> */}
                                                    <p className="fs--14 text--muted ms-1">{selectedClientInfo?.email}</p>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {clientType === 'corporate' && selectedCopClientInfo && (
                                        <div className="col-md-12 mt-4 client-info p-0 ms-2">
                                            <div className="info-head d-flex align-items-center justify-content-between">
                                                <h3 className="fs--18">{selectedCopClientInfo.name}</h3>
                                                {/* <a >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                        <g id="Group_856" data-name="Group 856" transform="translate(-971 -344)">
                                                            <rect id="Rectangle_276" data-name="Rectangle 276" width="30" height="30" rx="8" transform="translate(971 344)" fill="#ededee" />
                                                            <g id="write" transform="translate(971.004 344)">
                                                                <path id="Path_3528" data-name="Path 3528" d="M8.3,26.037,8,28.468a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#202529" />
                                                                <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.471,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.858a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#202529" />
                                                                <path id="Path_3530" data-name="Path 3530" d="M36.874,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.707A.583.583,0,0,0,36.874,52.583Z" transform="translate(-14.877 -31.168)" fill="#202529" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </a> */}
                                            </div>
                                            <div className="info-content mt-2">
                                                <p className="fs--14 text--muted">{selectedCopClientInfo.address}</p>
                                            </div>
                                            <div className="info-contact text-end mt-2">
                                                <span className="d-flex align-items-center flex-wrap justify-content-end">
                                                    <p className="fs--14 text--muted">{selectedCopClientInfo.phone} |</p>
                                                    <p className="fs--14 text--muted ms-1">{selectedCopClientInfo.email}</p>
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mt-4">
                                <label className="w-100">Order No.</label>
                                <input
                                    type="text"
                                    className="form-input mt-3"
                                    placeholder="Enter order no."
                                    name="order_number"
                                    // value={invoiceTitle.trim() !== '' ? orderNumber : ''}
                                    // readOnly
                                    value={formData.order_number}
                                    onChange={handleInputDateChange}

                                />
                            </div>
                            <div className="col-md-4 mt-4">
                                <label className="w-100">Invoice Date <span className="text-danger">*</span></label>
                                <input
                                    type="date"
                                    name="invoice_date"
                                    className="form-input mt-3"
                                    placeholder="INVR-02152"
                                    value={formData.invoice_date}
                                    onChange={handleInputDateChange}
                                    min={today} // Disable dates before toda
                                />
                                {error.invoice_date && <div className="error">{error.invoice_date}</div>}
                            </div>
                            {/* <div className="col-md-4 mt-4">
                                <label className="w-100">Due Date </label>
                                <input
                                    type="date"
                                    name="due_date"
                                    className="form-input mt-3"
                                    placeholder="INV-02152"
                                    value={formData.due_date}
                                    onChange={handleInputDateChange}
                                    min={today} // Disable dates before toda
                                />
                            </div> */}

                            <div className="col-md-4 mt-4">
                                <label className="w-100">Payment Term <span className="text-danger">*</span></label>
                                <select
                                    name="payment_term"
                                    id="payment_term"
                                    className="form-input mt-3"
                                    value={formData.payment_term}
                                    onChange={handleInputDateChange}
                                >
                                    <option value="" disabled="disabled" selected>Select</option>
                                    <option value="15">Net 15</option>
                                    <option value="30">Net 30</option>
                                    <option value="45">Net 45</option>
                                    <option value="60">Net 60</option>
                                    <option value="0">Due to receipt</option>
                                </select>
                                {error.payment_term && <div className="error">{error.payment_term}</div>}
                            </div>

                            <div className="col-md-4 mt-4">
                                <label className="w-100">Currency <span className="text-danger">*</span></label>
                                <select
                                    name="currency"
                                    id=""
                                    className="form-input mt-3"
                                    value={formData.currency}
                                    onChange={handleInputDateChange}
                                >
                                    <option value="" disabled="disabled" selected>Select</option>
                                    <option value="Canadian">Canadian Dollar</option>
                                    <option value="American">American Dollar</option>
                                    <option value="Australian">Australian Dollar</option>
                                    <option value="Indian">Indian Rupees</option>
                                </select>
                                {error.currency && <div className="error">{error.currency}</div>}
                            </div>
                        </div>

                        <hr className="mt-4" />
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="fs--18 font-semibold">Schedule</h4>
                                <p className="fs-14 text-muted">Choose the terms and start/end time to ensure that your customer receives your invoice on the correct day.</p>
                            </div>
                            <div className="col-md-4 mt-3">
                                <label className="w-100">Repeat this Invoice</label>
                                <select
                                    name="recurring_repeat"
                                    id="recurring_repeat"
                                    className="form-input mt-3"
                                    value={formData.recurring_repeat}
                                    onChange={handleInputDateChange}

                                >
                                    <option value="" disabled="">Select</option>
                                    <option value="1">Week</option>
                                    <option value="2">Monthly</option>
                                    <option value="3">Half Monthly</option>
                                    <option value="4">Yearly</option>
                                    <option value="5">Day</option>
                                </select>
                            </div>
                            <div className="col-md-4 mt-3">
                                <label className="w-100">Create First Invoice On</label>
                                <input
                                    type="date"
                                    className="form-input mt-3"
                                    name="recurring_start_date"
                                    id="recurring_start_date"
                                    value={formData.recurring_start_date}
                                    onChange={handleInputDateChange}
                                    min={today} // Disable dates before toda
                                />
                            </div>
                            {/* <div className="col-md-4 mt-3">
                                <label  className="w-100">Invoice End On</label>
                                <div className="d-flex align-items-center gap-3 mt-3">
                                    <input
                                        type="date"
                                        className="form-input"
                                        name="recurring_end_date"
                                        id="recurring_end_date"
                                        value={formData.recurring_end_date}
                                        onChange={handleInputDateChange}
                                        min={today} // Disable dates before toda
                                    />
                                    <span>-</span>
                                    <select
                                        name="recurring_expire"
                                        id="recurring_expire"
                                        className="form-input w-fit select_on"
                                        value={formData.recurring_expire}
                                        onChange={handleInputDateChange}
                                    >
                                        <option value="" disabled="">Select</option>
                                        <option value="0">Expried</option>
                                        <option value="1">Never Expried</option>

                                    </select>
                                </div>
                            </div> */}
                            <div className="col-md-4 mt-3">
                                <label className="w-100">Task End On</label>
                                <div className="d-flex align-items-center gap-2 mt-3">
                                    <input
                                        type="date"
                                        className="form-input"
                                        name="reccuring_end_date"
                                        id="reccuring_end_date"
                                        value={endRecurringDate}
                                        onChange={handleDateChange}
                                        disabled={isCheckboxChecked}
                                    />
                                    <span>-</span>
                                    <input
                                        type="checkbox"
                                        name="reccuring_expire"
                                        id="reccuring_expire"
                                        className="form-input w-fit h-35 w-35"
                                        checked={isCheckboxChecked}
                                        onChange={handleRecurringCheckboxChange}
                                        disabled={endRecurringDate !== ''}
                                    />
                                    <label for="reccuring_expire" className="fs-14">Never Expires</label>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3">
                                <label className="w-100">Send Email Automatically</label>
                                <select
                                    name="recurring_email_automatic"
                                    id="recurring_email_automatic"
                                    value={formData.recurring_email_automatic}
                                    onChange={handleInputDateChange}
                                    className="form-input mt-3"
                                >
                                    <option value="" disabled="">Select</option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>

                                </select>
                            </div>
                            <div className="col-md-4 mt-3">
                                <label className="w-100">Status</label>
                                <select
                                    name="recurring_status"
                                    id="recurring_status"
                                    className="form-input mt-3"
                                    value={formData.recurring_status}
                                    onChange={handleInputDateChange}
                                >
                                    <option value="" disabled="">Select</option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                    <option value="3">Stopped</option>

                                </select>
                                {errors?.reccuring_status && <span className="error">{errors?.reccuring_status.message}</span>}
                            </div>
                        </div>
                        <hr className="mt-4" />
                        <div className="row">
                            <h4 className="fs--18 font-semibold">Add Items</h4>


                            {inputFields.map((field, index) => (
                                <>

                                    <div className="add-items-input overflow-x-scroll d-flex gap-2 mt-4 pb-1" key={field.id}>

                                        <div className="item-inp">
                                            <label htmlFor={`item-${index}`} className="w-100">Item <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                id={`item-${index}`}
                                                name="item"
                                                className="form-input mt-3"
                                                placeholder="Item Name"
                                                value={field.name}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            {error[`item${index}`] && <div className="error">{error[`item${index}`]}</div>}
                                        </div>
                                        <div className="desc-inp">
                                            <label htmlFor={`descriptions-${index}`} className="w-100">Description <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                id={`descriptions-${index}`}
                                                name="descriptions"
                                                className="form-input mt-3"
                                                placeholder="Enter Description"
                                                value={field.descriptions}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                            {error[`descriptions${index}`] && <div className="error">{error[`descriptions${index}`]}</div>}
                                        </div>
                                        <div className="qty-inp">
                                            <label htmlFor={`quantity-${index}`} className="w-100">Qty <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                id={`quantity-${index}`}
                                                name="quantity"
                                                className="form-input mt-3"
                                                placeholder="0"
                                                value={field.quantity}
                                                onChange={e => handleQuantityChange(field.id, e.target.value, index)}
                                            />
                                            {error[`quantity${index}`] && <div className="error">{error[`quantity${index}`]}</div>}
                                        </div>
                                        <div className="price-inp">
                                            <label htmlFor={`price-${index}`} className="w-100">Price <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                id={`price-${index}`}
                                                name="price"
                                                className="form-input mt-3"
                                                placeholder="00.00"
                                                value={field.price}
                                                onChange={e => handlePriceChange(field.id, e.target.value, index)}
                                            />
                                            {error[`price${index}`] && <div className="error">{error[`price${index}`]}</div>}
                                        </div>
                                        <div className="disc-inp position-relative discount">
                                            <label htmlFor={`discount-${index}`} className="w-100">Discount</label>
                                            <div className="per-inp d-flex align-items-center">
                                                <input
                                                    type="text"
                                                    id={`item_discount-${index}`}
                                                    name="item_discount"
                                                    className="form-input mt-3"
                                                    placeholder="0"
                                                    value={field.item_discount}
                                                    onChange={e => handleDiscountChange(field.id, e.target.value, index)}
                                                />
                                                <div className="per d-flex align-items-center px-2 gap-2 mt-3">
                                                    <p
                                                        className={`px-1 ${field.item_discount_price_type == 1 ? 'active' : ''}`}
                                                        onClick={() => handleItemDiscountTypeChange(field.id, '1', index)}
                                                    >
                                                        $
                                                    </p>
                                                    <p
                                                        className={`px-1 ${field.item_discount_price_type == 0 ? 'active' : ''}`}
                                                        onClick={() => handleItemDiscountTypeChange(field.id, '0', index)}
                                                    >
                                                        %
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="tax">
                                            <label>Tax</label>
                                            <select
                                                className="form-input mt-3"
                                                name="tax_id"
                                                id={`tax_id_${field.id}`}
                                                // value={field[index]?.tax_id}

                                                onChange={(e) => handleTaxChange(e, index)}
                                            >
                                                <option value="">Select</option>
                                                {getTaxList && getTaxList.map((data) => (
                                                    <option key={data.id} value={data.id + '_' + data.name + '_' + parseFloat(data.rate).toFixed(2)}>
                                                        {`${data.name} (${parseFloat(data.rate).toFixed(2)}%)`}
                                                    </option>
                                                ))}
                                            </select>

                                        </div>
                                        <div className="amount-inp">
                                            <label htmlFor={`amount-${index}`} className="w-100">Amount</label>
                                            <input
                                                type="text"
                                                id={`amount-${index}`}
                                                name="amount"
                                                className="form-input mt-3"
                                                placeholder="0"
                                                value={field.amount}
                                                readOnly
                                            />
                                        </div>
                                    </div >
                                    <div className="text-end  w-100">
                                        {index !== 0 && (
                                            <a
                                                type="button"
                                                onClick={() => handleRemoveFields(field.id, index)}
                                                className="text-danger align-content-center h-100"
                                            >
                                                <i className="fa fa-trash"></i>
                                            </a>
                                        )}
                                    </div>

                                </>
                            ))}
                            <a className="text-blue d-block width-fit mt-2" onClick={handleAddFields}>+ Add Items</a>
                            <div className="row">
                                <div className="light-blue-bg p-4 my-4">
                                    <div className="row total">
                                        <div className="col-md-12 text-end d-flex align-items-center justify-content-end">
                                            <h6 className="text-muted">Sub Total:</h6>
                                            <h6 className="font-weight-bold text-muted ms-5"><b>${finalGstAmountSubTotal}</b></h6>
                                        </div>
                                        {
                                            totalGst && totalGst.map((item, index) => (
                                                <div key={index} className="col-md-12 text-end d-flex align-items-center justify-content-end mt-4">
                                                    <h6 className="text-muted">{item.type}</h6>
                                                    <h6 className="font-weight-bold text-muted ms-5"><b>${item.value.toFixed(2)}</b></h6>
                                                </div>
                                            ))
                                        }
                                        <div className="col-md-12 text-end d-flex align-items-center justify-content-end mt-4">
                                            <h6 className="text-muted">Total Discount:</h6>
                                            <h6 className="font-weight-bold text-muted ms-5"><b>${totalDiscountPrice}</b></h6></div>
                                    </div>

                                    <hr className="mt-4" />
                                    <div className="row total">
                                        <div className="col-md-12 text-end d-flex align-items-center justify-content-end">
                                            <h6 className="text-blue">Total:</h6>
                                            <h6 className="font-weight-bold text-blue ms-5"><b>${finalAmountSubTotal.toFixed(2)}</b></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="notes-inp p-0">
                                    <label className="w-100">Notes</label>
                                    <textarea name="notes" className="form-input mt-3" id="" cols="30" rows="3" value={formData.notes}
                                        onChange={handleInputDateChange}>Type here....</textarea>
                                </div>

                                <div className="form-btns text-end d-flex items-center flex-wrap gap-2 mt-4 justify-content-end p-0">
                                    <button className="btn-transparent" onClick={() => navigate(`/admin/invoice/invoice_detail`)}>Cancel</button>
                                    <button className="add-new">Save as Draft</button>
                                    <button className="add-new" type="submit">Save</button>
                                </div>
                            </div>



                        </div>

                    </form>
                </div>
            </div>
            {/* <Modal
                show={showModal}
                onHide={setShowModal}
                centered

                contentClassName="mx-auto"
                className="modal  font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                    <h4 className="fs-20">Add Individual Client</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>


                <div className="modal-body px-4 pt-0 pb-0">
                    <form onSubmit={handleSubmit(addClient)}>
                        <div className="row mt-4" >
                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">First Name</label>
                                <input
                                    name="first_name"
                                    id="first_name"
                                    className="form-input mt-2"
                                    type="text"
                                    {...register("first_name", {
                                        required: true,
                                    })}

                                />
                            </div>
                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">Middle Name</label>
                                <input
                                    name="middle_name"
                                    id="middle_name"
                                    className="form-input mt-2"
                                    type="text"
                                    {...register("middle_name", {
                                        required: true,
                                    })}

                                />
                            </div>
                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">Last Name</label>
                                <input
                                    name="last_name"
                                    id="last_name"
                                    className="form-input mt-2"
                                    type="text"
                                    {...register("last_name", {
                                        required: true,
                                    })}

                                />
                            </div>
                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">Email</label>
                                <input
                                    name="email"
                                    id="email"
                                    className="form-input mt-2"
                                    type="text"
                                    {...register("email", {
                                        required: true,
                                    })}

                                />
                            </div>

                            <div className="modal-footer border-0 px-0 pb-4 mt-4">
                                <a ><button className="btn-blue" type="submit">Add</button></a>
                            </div>
                        </div>

                    </form>
                </div>
            </Modal>
            <Modal
                show={showCopModal}
                onHide={() => setShowCopModal(false)}
                centered

                contentClassName="mx-auto"
                className="modal  font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                    <h4 className="fs-20">Add Individual Client</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>


                <div className="modal-body px-4 pt-0 pb-0">
                    <form onSubmit={handleSubmit(addBusiness)}>
                        <div className="row mt-4" >
                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">First Name</label>
                                <input
                                    name="name"
                                    id="name"
                                    className="form-input mt-2"
                                    type="text"
                                    {...register("name", {
                                        required: true,
                                    })}

                                />
                            </div>


                            <div className="col-lg-12 col-md-12 mt-4">
                                <label className="fs-18">Email</label>
                                <input
                                    name="email"
                                    id="email"
                                    className="form-input mt-2"
                                    type="text"
                                    {...register("email", {
                                        required: true,
                                    })}

                                />
                            </div>

                            <div className="modal-footer border-0 px-0 pb-4 mt-4">
                                <a ><button className="btn-blue" type="submit">Add</button></a>
                            </div>
                        </div>

                    </form>
                </div>
            </Modal> */}

        </div >


    )
}
export default Add_Reccuring_Invoice;