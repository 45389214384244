import React, {useState} from "react";
import { Modal } from "react-bootstrap";

const Roles = () => {
const [inviteModal, setinviteModal] = useState(false)


    return (
        <>
        <div className="bg-white rounded-4 shadow-main h-800 overflow-y-scroll pb-4 me-4">
            <div className="accord-head d-flex align-items-start justify-content-between px-4 pt-4 pb-0">
                <div>
                    <h4 className="fs-20 font-weight-bold">Add Roles</h4>
                    <p className="t_blue">Users & Roles</p>
                </div>
            </div>
            <div className="px-2">
                <div className="row">
                    <div className="col-lg-6 mt-3">
                        <label htmlFor="">Role Name</label>
                        <input type="text" className="form-input mt-1" placeholder="Type here..." />
                    </div>
                    <div className="col-lg-12 mt-3">
                        <label htmlFor="">Description</label>
                        <textarea name="" className="mt-1 form-input" id="" cols="10" rows="5"></textarea>
                    </div>
                </div>
                <hr className="my-3 mx-2" />
                <div className="row">
                    <div className="col-lg-12">
                        <h4 className="fs-18 fw-semibold">Set Permissions</h4>
                    </div>

                    <div className="col-md-8 mx-auto permboxes">
                        <div className="mt-4 d-flex align-items-start gap-3">
                            <div className="permtitle">
                                <h6 className="fs-18 fw-semibold">Task</h6>
                            </div>
                            <ul>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Complete Access of Task</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Create New Task</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">View Task</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Assign Task</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Update Task</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Delete Task</label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <hr className="my-3 mx-2" />

                    <div className="col-md-8 mx-auto permboxes">
                        <div className="d-flex align-items-start gap-3">
                            <div className="permtitle">
                                <h6 className="fs-18 fw-semibold">Service</h6>
                            </div>
                            <ul>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Complete Access of Service</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Create New Service</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">View Service</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Update Service</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Delete Service</label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <hr className="my-3 mx-2" />

                    <div className="col-md-8 mx-auto permboxes">
                        <div className="d-flex align-items-start gap-3">
                            <div className="permtitle">
                                <h6 className="fs-18 fw-semibold">Director</h6>
                            </div>
                            <ul>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Complete Access of Director</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Create New Director</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">View Director</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Update Director</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Delete Director</label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <hr className="my-3 mx-2" />

                    <div className="col-md-8 mx-auto permboxes">
                        <div className="d-flex align-items-start gap-3">
                            <div className="permtitle">
                                <h6 className="fs-18 fw-semibold">Invoice</h6>
                            </div>
                            <ul>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Complete Access of Invoice</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Create New Invoice</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">View Invoice</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Update Invoice</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Delete Invoice</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Send Invoice</label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <hr className="my-3 mx-2" />

                    <div className="col-md-8 mx-auto permboxes">
                        <div className="d-flex align-items-start gap-3">
                            <div className="permtitle">
                                <h6 className="fs-18 fw-semibold">Individual Advance</h6>
                            </div>
                            <ul>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Complete Access of Individual Advance</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Create New Individual Advance Details</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">View Individual Advance Details</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Update Individual Advance Details</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Delete Individual Advance Details</label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <hr className="my-3 mx-2" />

                    <div className="col-md-8 mx-auto permboxes">
                        <div className="d-flex align-items-start gap-3">
                            <div className="permtitle">
                                <h6 className="fs-18 fw-semibold">Business Advance</h6>
                            </div>
                            <ul>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Complete Access of Business Advance</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Create New Business Advance Details</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">View Business Advance Details</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" className="rounded-full" />
                                    <label htmlFor="task1">Update Business Advance Details</label>
                                </li>
                                <li className="d-flex gap-2 align-items-center mb-2">
                                    <input type="checkbox" id="task1" />
                                    <label htmlFor="task1">Delete Business Advance Details</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>

            {/* MODAL START */}
            <Modal
            show={inviteModal}
            onHide={setinviteModal}
            centered
            size="md" 
            contentClassName="mx-auto p-3 h-auto"
            className="modal pin-modal font-poppins" 
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header p-0 justify-content-between align-items-center border-0">
                <h4 className="fs-20">Invite Modal</h4>
                <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
            </div>
            <div className="services-form row">
                <div className="col-lg-12 mt-3 p-0">
                    <form>
                        <div className="border-0 justify-content-center p-0">
                            <label htmlFor="name">User Name</label>
                                <input
                                    name="name"
                                    id="name"
                                    className="form-input mx-0 mt-1"
                                    type="text"
                                    placeholder="Type here..."
                                />
                        </div>
                        <div className="border-0 justify-content-center p-0 mt-3">
                            <label htmlFor="name">Email</label>
                                <input
                                    name="name"
                                    id="name"
                                    className="form-input mx-0 mt-1"
                                    type="text"
                                    placeholder="Type here..."
                                />
                        </div>
                        <div className="border-0 justify-content-center p-0 mt-3">
                            <label htmlFor="name">Phone No.</label>
                                <input
                                    name="name"
                                    id="name"
                                    className="form-input mx-0 mt-1"
                                    type="number"
                                    placeholder="Type here..."
                                />
                        </div>
                        <hr className="my-4 opacity-25"/>
                        <div className="roles">
                            <h4 className="fs--18">Set Roles</h4>
                            <p className="fs--14 text-muted">You can assign multiple roles to a user for flexible permissions and access.</p>

                            <div className="d-flex gap-4 flex-wrap">
                                <div className="checks">
                                    <div className="input-grp my-2">
                                        <input id="createtask" type="checkbox" />
                                        <label htmlFor="createtask">Create New Task</label>
                                    </div>
                                    <div className="input-grp my-2">
                                        <input id="employee" type="checkbox" />
                                        <label htmlFor="employee">Employee</label>
                                    </div>
                                    <div className="input-grp my-2">
                                        <input id="superviser" type="checkbox" />
                                        <label htmlFor="superviser">Superviser</label>
                                    </div>
                                </div>
                                <div className="checks">
                                    <div className="input-grp my-2">
                                        <input id="admin" type="checkbox" />
                                        <label htmlFor="admin">Admin</label>
                                    </div>
                                    <div className="input-grp my-2">
                                        <input id="guest" type="checkbox" />
                                        <label htmlFor="guest">Guest</label>
                                    </div>
                                    <div className="input-grp my-2">
                                        <input id="user" type="checkbox" />
                                        <label htmlFor="user">User</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 border-0">
                            <button type="submit" className="btn-blue ms-auto">Add</button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
            {/* MODAL END */}
        </>


    )
}
export default Roles;